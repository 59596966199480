import React, { useState, useEffect } from 'react';
import xlsxLogo from '../xlsx.svg';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { orange, purple } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  logoutButton: {
    position: "absolute",
    right: 16,
    top: 116,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Button);

function onFacultatiSelected(e) {
  console.log("Changed");
}

function Home() {
  const [responseJson, setResponseJson] = useState({});
  const [facultatiJson, setFacultatiJson] = useState([]);
  const [profileJson, setProfileJson] = useState([]);
  const [specializariJson, setSpecializariJson] = useState([]);

  const [selectedFacultate, setSelectedFacultate] = useState("");
  const [selectedProfil, setSelectedProfil] = useState("");
  const [selectedSpecializare, setSelectedSpecializare] = useState("");

  const [loadingControlDisabled, setLoadingControlDisabled] = useState(true);

  const [isLoading, setLoading] = useState(true);

  const classes = useStyles();

  var selectedFacultateIndex = 0;
  var selectedProfilIndex = 0;

  

  useEffect(() => {
    fetch("/get/facultati/")
      .then((response) => response.json())
      .then((rJson) => {
        // console.log(rJson);
        // console.log(Object.keys(rJson));
        setResponseJson(rJson);
        setLoadingControlDisabled(false);
        const el = document.querySelector(".loader-container");
        if (el) {
          el.innerHTML = "";
          setLoading(!isLoading);
        }
      });
    
  }, []);

  useEffect(() => {
    if(!(Object.keys(responseJson).length === 0 && responseJson.constructor === Object)) {
      createFacultatiSelectItem();
    }
  }, [responseJson]);

  useEffect(() => {
    if(facultatiJson.length != 0) {
      setSelectedFacultate(facultatiJson[0]);
      createProfileSelectItem();
    }
  }, [facultatiJson]);

  useEffect(() => {
    if(profileJson.length != 0) {
      setSelectedProfil(profileJson[0].denumire_profil);
      createSpecializariSelectItem();
    }
  }, [profileJson]);

  useEffect(() => {
    if(specializariJson.length != 0) {
      setSelectedSpecializare(specializariJson[0].cod_profil + " " + specializariJson[0].cod_specializare)
    }
  }, [specializariJson]);

  
  function createFacultatiSelectItem() {
    var facultati = [];

    Object.keys(responseJson).forEach((facultate) => facultati.push(facultate));

    setFacultatiJson(facultati);
  }

  function createProfileSelectItem() {
    var profile = [];
    
    responseJson[facultatiJson[selectedFacultateIndex]].forEach((item) => {
      var found = false;
      for(var i = 0; i < profile.length; i++) {
        if(profile[i].denumire_profil == item.denumire_profil) {
          found = true;
          break;
        }
      }

      if(!found)
        profile.push({denumire_profil: item.denumire_profil});
    });

    setProfileJson(profile);
  }

  function createSpecializariSelectItem() {
    var specializari = [];

    responseJson[facultatiJson[selectedFacultateIndex]].forEach((item) => {
      if(profileJson[selectedProfilIndex].denumire_profil == item.denumire_profil)
        specializari.push({cod_profil: item.cod_profil, cod_specializare: item.cod_specializare, denumire_specializare: item.denumire_specializare});
    });

    setSpecializariJson(specializari);
  }

  function handleFacultateSelect(e) {
    for(var i = 0; i < facultatiJson.length; i++)
      if(facultatiJson[i] == e.target.value) {
        selectedFacultateIndex = i;
        break;
      }
    createProfileSelectItem();
  }

  function handleProfilSelect(e) {
    for(var i = 0; i < profileJson.length; i++)
      if(profileJson[i].denumire_profil == e.target.value) {
        selectedProfilIndex = i;
        break;
      }
    setSelectedProfil(e.target.value);
    createSpecializariSelectItem();
  }

  function handleSpecializareSelect(e) {
    setSelectedSpecializare(e.target.value);
  }

  // const fileName = materie.AN + "_" + materie.DEN_DISC + "_" + materie.PREFIX.replace(replacer1, '') + "_" + materie.COD_SPEC.replace(replacer1, '').replace(replacer2, '') + ".xlsx";
  return (
    <div className="Home" >
      {/* <Button
        variant="contained"
        className={classes.logoutButton}
        size="large"
        startIcon={<ExitToAppIcon />}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        Deconectează-te
      </Button> */}
      {/* <IconButton 
        component="span"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon fontSize="large" />
      </IconButton> */}

      <Fab 
        size="large"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon />
      </Fab>
      <center>
        <form class="login-form" action="/materii" method="POST" noValidate>
          <FormControl className={classes.formControl}>
            <InputLabel id="facultate-select-label">Facultatea</InputLabel>
            <Select
              labelId="facultate-select-label"
              name="FACULTATE"
              value={selectedFacultate}
              defaultValue={selectedFacultate}
              onChange={(e) => handleFacultateSelect(e)}
              disabled={loadingControlDisabled}
            >
              {facultatiJson.map((facultate) => (
                  <MenuItem value={facultate}>{facultate}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br></br>
          <FormControl className={classes.formControl}>
            <InputLabel id="profil-select-label">Domeniul</InputLabel>
            <Select
              labelId="profil-select-label"
              name="PROFIL"
              value={selectedProfil}
              onChange={(e) => handleProfilSelect(e)}
              disabled={loadingControlDisabled}
            >
              {profileJson.map((profil) => (
                  <MenuItem value={profil.denumire_profil}>{profil.denumire_profil}</MenuItem>
              ))}
            </Select>
          </FormControl> 
          <br></br>
          <FormControl className={classes.formControl}>
            <InputLabel id="specializare-select-label">Specializarea</InputLabel>
            <Select
              labelId="specializare-select-label"
              name="SPECIALIZARE"
              value={selectedSpecializare}
              onChange={(e) => handleSpecializareSelect(e)}
              disabled={loadingControlDisabled}
            >
              {specializariJson.map((specializare) => (
                  <MenuItem value={specializare.cod_profil + " " + specializare.cod_specializare}>{specializare.denumire_specializare + " - " + specializare.cod_profil + "_" + specializare.cod_specializare}</MenuItem>
              ))}
            </Select>
          </FormControl> 
          <br></br>
          <Button
            type="Submit"
            name="OPTIUNE"
            value="catalog"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              const el = document.querySelector(".loader-container");
              el.innerHTML = "<div class=\"loader\"></div>";
              setLoading(!isLoading);
            }}
            disabled={loadingControlDisabled}
          >
            Catalog
          </Button>
          {/*<Button
            type="Submit"
            name="OPTIUNE"
            value="evaluari"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              const el = document.querySelector(".loader-container");
              el.innerHTML = "<div class=\"loader\"></div>";
              setLoading(!isLoading);
            }}
            disabled={loadingControlDisabled}
          >
            Evaluări
          </Button>*/}
          <br />
          <ColorButton
            variant="contained" 
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              const el = document.querySelector(".loader-container");
              el.innerHTML = "<div class=\"loader\"></div>";
              setLoading(!isLoading);
              window.location.href='/statistici';
            }}
            className={classes.button}
            component="span"
            // disabled={true}
          >
            Statistici încărcări
          </ColorButton>
        </form>
        
        {/* /////////////////////////////////////////////////////////} */}
        {/* <form action="/materii" method="POST">
          <div>
            <select name="FACULTATE" onChange={e => handleFacultateSelect(e)} >
              {facultatiJson.map((facultate) => (
                <option value={facultate}>{facultate}</option>
              ))}
            </select><br></br>
            <select value={selectedProfil} name="PROFIL" onChange={e => handleProfilSelect(e)} >
              {profileJson.map((profil) => (
                <option value={profil.denumire_profil}>{profil.denumire_profil}</option>
              ))}
            </select><br></br>
            <select value={selectedSpecializare} name="SPECIALIZARE" onChange={e => handleSpecializareSelect(e)} >
              {specializariJson.map((specializare) => (
                <option value={specializare.cod_profil + " " + specializare.cod_specializare}>{specializare.denumire_specializare + " - " + specializare.cod_profil + "_" + specializare.cod_specializare}</option>
              ))}
            </select>
          </div>
          <div>
          <div><input type="submit" value="Selecteaza" name="selecteaza" onClick={() => {
            const el = document.querySelector(".loader-container");
            el.innerHTML = "<div class=\"loader\"></div>";
            setLoading(!isLoading);
          }}/></div>
          </div>
        </form>  */}
      </center>
    </div>
  ); 
}

export default Home;
