import React, { useState, useEffect } from 'react';
import xlsxLogo from '../xlsx.svg'

import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dialogDescription: {
    margin: theme.spacing(1),
    textAlign: "justify",
  },
  errorInfoDialog: {
    position: "absolute",
    top: theme.spacing(2),
  },
}));

function Login() {
  const [isLoading, setLoading] = useState(true);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfoDescription, setErrorInfoDescription] = useState("");
  const [logInEnabled, setLogInEnabled] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const el = document.querySelector(".loader-container");
    if (el) {
      el.innerHTML = "";
      setLoading(!isLoading);
    }
  }, []);

  useEffect(() => {
    if(errorInfoDescription != "") {
      setOpenErrorInfo(true);
    }
  }, [errorInfoDescription]);

  useEffect(() => {
    if(openErrorInfo == false) {
      setErrorInfoDescription("");
    }
  }, [openErrorInfo]);

  function formOnSubmit(e) {
    e.preventDefault();

    if(document.getElementById("utilizator").value.length == 0) {
      return;
    }

    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);
    // console.log(document.getElementById("utilizator").value);
    // console.log(document.getElementById("parola").value);


    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/autentificare/login", true);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = function() {
      var response = JSON.parse(xhr.response);

      if(response.success == true) {
        if(response.data == "secretariat")
          window.location.href='/home';
        else if(response.data == "didactic")
          window.location.href='/materii/lista-materii-cd';
      }
      else {
        document.getElementById("parola").value = "";
        el.innerHTML = "";
        setLoading(!isLoading);
        setErrorInfoDescription(response.message);
      }
      
    }

    xhr.send(JSON.stringify({
        utilizator: document.getElementById("utilizator").value,
        parola: document.getElementById("parola").value
    }));
    
  }

  const handleCloseErrorInfo = () => {
    setOpenErrorInfo(false);
  };

  function handleUserChanged(e) {
    if(document.getElementById("utilizator").value.length == 0) {
      setLogInEnabled(false);
    }
    else {
      setLogInEnabled(true);
    }
  }

  return (
    <div className="Login">
      <center>
        <form action="/autentificare/login" method="POST" onSubmit={e => formOnSubmit(e)}>
          <TextField 
            id="utilizator" 
            name="utilizator" 
            className={classes.formControl} 
            label="Utilizator"
            onChange={(e) => handleUserChanged(e)}
          />
          <br></br>
          <TextField id="parola" name="parola" type="password" className={classes.formControl} label="Parola"/><br></br>
          <Button
            type="Submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              const el = document.querySelector(".loader-container");
              el.innerHTML = "<div class=\"loader\"></div>";
              setLoading(!isLoading);
            }}
            disabled={!logInEnabled}
          >
            Intră
          </Button>
        </form> 
      </center>
      <Dialog classes={{paper: classes.errorInfoDialog}} onClose={handleCloseErrorInfo} aria-labelledby="simple-dialog-error-info" open={openErrorInfo}>
        <DialogTitle id="simple-dialog-error-info">{"Eroare"}</DialogTitle>
        <center className={classes.dialogDescription}>{errorInfoDescription}</center>
      </Dialog>
    </div>
  ); 
}

export default Login;
