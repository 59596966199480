import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import xlsxLogo from '../xlsx.svg';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';

import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import PublishIcon from '@material-ui/icons/Publish';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import  GetApp  from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import { orange, green, red } from '@material-ui/core/colors';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  xlsxLogo: {
    marginRight: theme.spacing(1),
    height: 16,
    width: 16,
  },
  dialogDescription: {
    margin: theme.spacing(1),
    textAlign: "justify",
  },
  logoutButton: {
    position: "absolute",
    right: 16,
    top: 116,
  },
  errorInfoDialog: {
    position: "absolute",
    top: theme.spacing(2),
  },
  paperWidth: {
    width: "fit-content",
  },
  rightAlignText: {
    textAlign: "right",
  },
}));

var attempts = 40;
var downloadTimer;

function expireCookie( cName ) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function getCookie( name ) {
  var parts = document.cookie.split(name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}


function Statistici() {
  const [statisticiJson, setStatisticiJson] = useState();
  const [statisticiFiltrateJson, setStatisticiFiltrateJson] = useState([]);
  const [facultati, setFacultati] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [materiiIncarcate, setMateriiIncarcate] = useState(0);
  const [materiiTotal, setMateriiTotal] = useState(0);
  const [facultateFilter, setFacultateFilter] = useState("AC");
  const [semestruFilter, setSemestruFilter] = useState(1);
  const [loadingControlDisabled, setLoadingControlDisabled] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    fetch("/get/statisticiIncarcari")
      .then((response) => response.json())
      .then(sJson => {
        setFacultati(Object.keys(sJson));
        setStatisticiJson(sJson);
        setLoadingControlDisabled(false);
        const el = document.querySelector(".loader-container");
        if (el) {
          el.innerHTML = "";
          setLoading(!isLoading);
        }
      });
  }, []);

  useEffect(() => {
    if(statisticiJson != undefined && facultateFilter != undefined) {
      filterStatistici();
    }
  }, [statisticiJson, facultateFilter, semestruFilter]);

  useEffect(() => {
    if(facultati.length != 0) {
      setFacultateFilter(facultati[0]);
    }
  }, [facultati]);

  function filterStatistici() {
    var statisticiFiltrate = [];
    var incarcate = 0;
    var total = 0;

    console.log(statisticiJson);
    Object.keys(statisticiJson[facultateFilter]["specializari"]).forEach((specializareKey) => {
      var specializare = statisticiJson[facultateFilter]["specializari"][specializareKey];
      incarcate += (semestruFilter != "" ? specializare[semestruFilter] : specializare["1"] + specializare["2"]);
      total += semestruFilter != "" ? specializare[semestruFilter + '_total'] : specializare["1_total"] + specializare["2_total"]

      statisticiFiltrate.push({
        domeniu: specializare.domeniu,
        specializare: specializare.specializare,
        cod_domeniu: specializare.cod_domeniu,
        cod_specializare: specializare.cod_specializare,
        incarcat: semestruFilter != "" ? specializare[semestruFilter] : specializare["1"] + specializare["2"],
        total: semestruFilter != "" ? specializare[semestruFilter + '_total'] : specializare["1_total"] + specializare["2_total"]
      });
    });

    setStatisticiFiltrateJson(statisticiFiltrate);
    setMateriiIncarcate(incarcate);
    setMateriiTotal(total);
  }

  function handleDownloadStatistici(facultate, domeniu, specializare, semestru) {
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/statistici/upload/download", true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        var blob = new Blob([xhr.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = facultate;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);
      }
      expireCookie("downloaded");
      el.innerHTML = "";
      setLoading(!isLoading);
    }

    const formData = new FormData();
    
    formData.append('FACULTATE', facultate);
    formData.append('DOMENIU', domeniu);
    formData.append('SPECIALIZARE', specializare);
    formData.append('SEMESTRU', semestru);


    xhr.send(formData);
  }
  
  function handleFacultateSelectFilterSelected(e) {
    setFacultateFilter(e.target.value);
  }

  function handleSemestruSelectFilterSelected(e) {
    setSemestruFilter(e.target.value);
  }


  return (
    <div className="Statistici">
      <Fab 
        size="large"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon />
      </Fab>
      <h2 className={classes.margin}>Statistici încărcări</h2>

      <FormControl className={classes.formControl}>
        <InputLabel>Facultatea</InputLabel>
        <Select
          id="facultateSelectFilter"
          defaultValue={facultateFilter}
          onChange={(e) => handleFacultateSelectFilterSelected(e)}
          disabled={loadingControlDisabled}
        >
          {facultati.map((facultate) => (
            <MenuItem value={facultate}>{facultate}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Paper className={classes.paperWidth}>
        <div style={{display: "block", fontSize: "1.17em", marginTop: "1em", marginBottom: "1em", marginLeft: "0", marginRight: "0", fontWeight: "bold"}}>
          {`Total materii sincronizate pe facultate: ${materiiIncarcate} (${Math.round((materiiIncarcate * 100 / materiiTotal + Number.EPSILON) * 100) / 100}%) din ${materiiTotal}`}
          <Button
            variant="contained" 
            color="primary"
            onClick={() => handleDownloadStatistici(facultateFilter, "", "", semestruFilter)}
            className={classes.button}
            startIcon={<GetApp />}
            component="span"
            // disabled={true}
          >
            Descarcă
          </Button>
        </div>
      </Paper>
      <br />

      <FormControl className={classes.formControl}>
        <InputLabel>Semestrul</InputLabel>
        <Select
          id="semestruSelectFilter"
          defaultValue={semestruFilter}
          onChange={(e) => handleSemestruSelectFilterSelected(e)}
          disabled={loadingControlDisabled}
        >
          <MenuItem value=''>Ambele</MenuItem>
          {[1, 2].map((semestru) => (
            <MenuItem value={semestru}>{semestru}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell>Domeniul</TableCell>
            <TableCell>Specializarea</TableCell>
            <TableCell>{`Discipline încărcate${(semestruFilter != "" ? ` sem ${semestruFilter}` : "")}`}</TableCell>
            <TableCell>{`Total discipline${(semestruFilter != "" ? ` sem ${semestruFilter}` : "")}`}</TableCell>
            <TableCell>Descarca</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statisticiFiltrateJson.map(statistica => (
            <TableRow>
              <TableCell>
                {statistica.domeniu == null ? null : (statistica.domeniu.length > 70 ? (`${statistica.domeniu.substr(0 ,60)}...`) : `${statistica.domeniu}`)}
              </TableCell>
              <TableCell>
                {`${statistica.specializare == null ? null : (statistica.specializare.length > 70 ? (`${statistica.specializare.substr(0 ,60)}...`) : `${statistica.specializare}`)} - ${statistica.cod_domeniu}_${statistica.cod_specializare}`}
              </TableCell>
              <TableCell>
                <div style={{color: (statistica.incarcat == 0 ? 'red' : (statistica.incarcat != statistica.total ? 'orange' : 'green'))}}>
                  <div className={classes.rightAlignText}>
                    {`${statistica.incarcat} (${Math.round((statistica.incarcat * 100 / statistica.total + Number.EPSILON) * 100) / 100}%)`}
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <div className={classes.rightAlignText}>
                  {statistica.total}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  ); 
}

export default Statistici;
