import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import xlsxLogo from '../xlsx.svg';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';

import { orange } from '@material-ui/core/colors';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  xlsxLogo: {
    marginRight: theme.spacing(1),
    height: 16,
    width: 16,
  },
  dialogDescription: {
    margin: theme.spacing(1),
    textAlign: "justify",
  },
  logoutButton: {
    position: "absolute",
    right: 16,
    top: 116,
  },
  notaCell: {
    padding: 0,
  },
  notaText: {
    marginRight: theme.spacing(2),
    textAlign: "right",
  },
  textAlignRight: {
    textAlign: "right",
  },
  square: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

const ColorFab = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[800]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Fab);

var attempts = 40;
var downloadTimer;

const bgColors = {
  "": "#DDDDDD",
  "m": "#FFA500A0",
  "a": "#00FF00A0",
  "r": "#FF0000A0"
};

function expireCookie( cName ) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function getCookie( name ) {
  var parts = document.cookie.split(name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MateriiSecretariat() {
  const [materiiJson, setMateriiJson] = useState([]);
  const [materiiFiltrateJson, setMateriiFiltrateJson] = useState([]);
  const [diferenteJson, setDiferenteJson] = useState([]);
  const [istoricIncarcariJson, setIstoricIncarcariJson] = useState({materie: {}, incarcari: []});
  const [studentiJson, setStudentiJson] = useState([]);
  const [aniStudiu, setAniStudiu] = useState([]);
  const [anFilter, setAnFilter] = useState("");
  const [semestruFilter, setSemestruFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [profilCurent, setProfilCurent] = useState({});
  const [open, setOpen] = useState(false);
  const [openIstoricIncarcari, setOpenIstoricIncarcari] = useState(false);
  const [openPrevizualizareNote, setOpenPrevizualizareNote] = useState(false);
  const [openDiferenteSincronizari, setOpenDiferenteSincronizari] = useState(false);
  const [disciplinaSelectata, setDisciplinaSelectata] = useState("");
  const [cadruSelectat, setCadruSelectat] = useState();
  const [cadreJson, setCadreJson] = useState([]);
  const [cadreFiltrateJson, setCadreFiltrateJson] = useState([]);
  const [departamente, setDepartamente] = useState([]);
  const [departamentFilter, setDepartamentFilter] = useState("");
  const [asociazaDisabled, setAsociazaDisabled] = useState(true);
  const [loadingControlDisabled, setLoadingControlDisabled] = useState(true);

  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfoDescription, setErrorInfoDescription] = useState("");

  const [isLoading, setLoading] = useState(true);

  const [uploadEnabled, setUploadEnabled] = useState(false);




  const classes = useStyles();

  useEffect(() => {
    fetch("/get/materii")
      .then((response) => response.json())
      .then(mJson => {
        getAniStudiu(mJson);
        setMateriiJson(mJson);
        setMateriiFiltrateJson(mJson);
        setLoadingControlDisabled(false);
        const el = document.querySelector(".loader-container");
        if (el) {
          el.innerHTML = "";
          setLoading(!isLoading);
        }
      });
    
    fetch("/get/profilCurent")
    .then((response) => response.json())
    .then(pJson => setProfilCurent(pJson));

    fetch("/get/cadre")
    .then((response) => response.json())
    .then(cJson => {
      getDepartamente(cJson);
      setCadreJson(cJson);
      setCadreFiltrateJson(cJson);
    });

    fetch("/get/configUpload")
    .then((response) => response.json())
    .then(rJson => {
      setUploadEnabled(rJson.secretariate);
    });
    
  }, []);

  useEffect(() => {
    if(disciplinaSelectata != "")
      setOpen(true);
  }, [disciplinaSelectata]);

  useEffect(() => {
    if(cadruSelectat !== undefined)
      setAsociazaDisabled(false);
  }, [cadruSelectat]);

  useEffect(() => {
    filterMaterii();
  }, [anFilter, semestruFilter, textFilter]);

  useEffect(() => {
    filterCadre();
  }, [departamentFilter]);

  useEffect(() => {
    if(errorInfoDescription != "") {
      setOpenErrorInfo(true);
    }
  }, [errorInfoDescription]);

  useEffect(() => {
    if(openErrorInfo == false) {
      setErrorInfoDescription("");
    }
  }, [openErrorInfo]);
    
  // var search1 = '\\.';
  // var search2 = "\\*";  

  // var replacer1 = new RegExp(search1, 'g');
  // var replacer2 = new RegExp(search2, 'g');

  function getAniStudiu(mJson) {
    var aniS = [];

    mJson.forEach((materie) => {
      if(materie.AN != null && !aniS.includes(materie.AN))
        aniS.push(materie.AN);
    });

    setAniStudiu(aniS);
  }

  function getDepartamente(cJson) {
    var dep = [];

    cJson.forEach((cadru) => {
      if(cadru.departament != null && !dep.includes(cadru.departament))
        dep.push(cadru.departament);
    });

    setDepartamente(dep);
  }

  function handleAnSelectFilterSelected(e) {
    setAnFilter(e.target.value);
  }

  function handleSemestruSelectFilterSelected(e) {
    setSemestruFilter(e.target.value);
  }

  function handleTextFilterChanged(e) {
    setTextFilter(e.target.value);
  }

  function handleDepartamentSelectFilterChanged(e) {
    setDepartamentFilter(e.target.value);
  }

  function handleEvaluareChanged(DISCIPLINA) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/editEvaluare", true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    
    xhr.onload = function() {
      var den_disciplina = DISCIPLINA.split("__")[2];
      var prefix_disciplina = DISCIPLINA.split("__")[0];
      var cod_disciplina = DISCIPLINA.split("__")[1];
      var evaluare_disciplina = DISCIPLINA.split("__")[3];

      materiiJson.forEach((materie) => {
        if(materie.DEN_DISC == den_disciplina && materie.PREFIX == prefix_disciplina && materie.COD_SPEC == cod_disciplina) {
          materie.EVALUARE = parseInt(evaluare_disciplina);
        }
      });
      filterMaterii();
    }

    xhr.send(JSON.stringify({
      DISCIPLINA: DISCIPLINA,

    }));
  }

  function filterMaterii() {
    var materiiFiltrate = [];

    materiiJson.forEach((materie) => {
      if(('' + materie.AN).includes(anFilter) && ('' + materie.semestru).includes(semestruFilter) && (materie.COD_SPEC.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(textFilter.toUpperCase()) || (materie.DEN_DISC + ((materie.VALABILITATE != "2023-2024" && materie.VALABILITATE != "-") ? ` (${materie.VALABILITATE})` : "")).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(textFilter.toUpperCase()) || materie.PREFIX.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(textFilter.toUpperCase()) || (materie.CADRU_DIDACTIC == undefined ? "" : materie.CADRU_DIDACTIC.nume).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(textFilter.toUpperCase()) ))
        materiiFiltrate.push(materie);
    });

    setMateriiFiltrateJson(materiiFiltrate);
  }

  
  function handleOnSubmitAsociaza(e) {
    e.preventDefault();
    setAsociazaDisabled(true);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/asign", true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    
    xhr.onload = function() {
      var DISCIPLINA = document.getElementById("DISCIPLINA").value;
      var den_disciplina = DISCIPLINA.split("__")[2];
      var prefix_disciplina = DISCIPLINA.split("__")[0];
      var cod_disciplina = DISCIPLINA.split("__")[1];
      
      materiiJson.forEach((materie) => {
        if(materie.DEN_DISC == den_disciplina && materie.PREFIX == prefix_disciplina && materie.COD_SPEC == cod_disciplina) {
          if(cadruSelectat != null) {
            if(materie.CADRU_DIDACTIC == undefined)
              materie.CADRU_DIDACTIC = {};
            materie.CADRU_DIDACTIC.email = cadruSelectat.email;
          }
          else {
            delete materie["CADRU_DIDACTIC"];
          }
        }
      });
      setOpen(false);
      setCadruSelectat(null);
      setDisciplinaSelectata("");
      
    }

    xhr.send(JSON.stringify({
      DISCIPLINA: document.getElementById("DISCIPLINA").value,
      CADRU: (cadruSelectat == null ? null : (cadruSelectat.nume + "__" + cadruSelectat.email + "__" + cadruSelectat.departament + "__" + cadruSelectat.titlu))
    }));
    
  }

  function handleDeleteAsociere(DISCIPLINA) {

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/asign", true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    
    xhr.onload = function() {
      var den_disciplina = DISCIPLINA.split("__")[2];
      var prefix_disciplina = DISCIPLINA.split("__")[0];
      var cod_disciplina = DISCIPLINA.split("__")[1];
      
      materiiJson.forEach((materie) => {
        if(materie.DEN_DISC == den_disciplina && materie.PREFIX == prefix_disciplina && materie.COD_SPEC == cod_disciplina) {
          delete materie["CADRU_DIDACTIC"];
        }
      });
      
      setMateriiJson([...materiiJson]);
    }

    xhr.send(JSON.stringify({
      DISCIPLINA: DISCIPLINA,
      CADRU: null
    }));
    
  }

  function handleMaterieDownloadClick(e, DEN_DISC, PREFIX, COD_SPEC, AN, VALABILITATE, EVALUARE, NUME_CADRU) {
    e.preventDefault();
    // console.log(NUME_CADRU);
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var search1 = '\\.';
    var search2 = "\\*";  

    var replacer1 = new RegExp(search1, 'g');
    var replacer2 = new RegExp(search2, 'g');

    // var semestru = COD_SPEC.split('.');
    // semestru = semestru[semestru.length - 1];
    // semestru = parseInt(semestru);

    // var AN = Math.ceil(semestru / 2);

    const fileName = AN + "_" + DEN_DISC + "_" + PREFIX.replace(replacer1, '') + "_" + COD_SPEC.replace(replacer1, '').replace(replacer2, '') + ".xlsx";


    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/download", true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/json") {
        const blob = new Blob([xhr.response], {type : 'application/json'});
        var response = JSON.parse(await blob.text());
        // setErrorInfoDescription(response.message);
      }
      else if(xhr.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        var blob = new Blob([xhr.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);
      }
      expireCookie("downloaded");
      el.innerHTML = "";
      setLoading(!isLoading);
      
      
    }

    const formData = new FormData();
    // '/materii/download?DEN_DISC=' + materie.DEN_DISC + "&PREFIX=" + materie.PREFIX + "&COD_SPEC=" + materie.COD_SPEC + "&AN=" + materie.AN + "&VALABILITATE=" + materie.VALABILITATE + "&NUME_CADRU=" + (materie.CADRU_DIDACTIC != undefined ? materie.CADRU_DIDACTIC.nume : "")
  
    formData.append('DEN_DISC', DEN_DISC);
    formData.append('PREFIX', PREFIX);
    formData.append('COD_SPEC', COD_SPEC);
    formData.append('AN', AN);
    formData.append('VALABILITATE', VALABILITATE);
    formData.append('EVALUARE', EVALUARE);
    formData.append('NUME_CADRU', (NUME_CADRU || ""));

    xhr.send(formData);
  }

  function handleIstoricDownloadClick(e, CALE) {
    e.preventDefault();

    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/istoric/download", true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        var blob = new Blob([xhr.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;

        var fileName = xhr.getResponseHeader('Content-Disposition');
        fileName = fileName.includes("filename*=UTF-8''") ? fileName.split("filename*=UTF-8''")[1] : fileName.split("filename=\"")[1].substring(0, fileName.split("filename=\"")[1].length - 1);
        
        fileName = decodeURIComponent(fileName);

        a.download = fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);
      }
      expireCookie("downloaded");
      el.innerHTML = "";
      setLoading(!isLoading);
    }

    const formData = new FormData();
    formData.append('CALE', CALE);

    xhr.send(formData);
  }


  function filterCadre() {
    var cadreFiltrate = [];

    cadreJson.forEach((cadruDidactic) => {
      if(('' + cadruDidactic.departament).includes(departamentFilter))
        cadreFiltrate.push(cadruDidactic);
    });

    setCadreFiltrateJson(cadreFiltrate);
  }

  function materieDownloadClick() {
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    downloadTimer = window.setInterval( function() {
      var token = getCookie( "downloaded" );

      if( (token == "true") || (attempts == 0) ) {
          expireCookie("downloaded");
          attempts = 40;
          window.clearInterval( downloadTimer );
          el.innerHTML = "";
          setLoading(!isLoading);
      }

      attempts--;
    }, 500 );
  }  

  function handleUploadFileChange(index) {
    if(document.getElementById("upload-file-button-" + index).value == "") {
      return;
    }

    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/upload", true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/json") {
        const blob = new Blob([xhr.response], {type : 'application/json'});
        var response = JSON.parse(await blob.text());

        if(response.success == false) {
          setErrorInfoDescription(response.message);
        }
        else {
          window.location.href='/upload/preview';
        }
      }
      else {
        
      }
      el.innerHTML = "";
      document.getElementById("upload-file-button-" + index).value = "";
      setLoading(!isLoading);
    }

    const files = document.getElementById("upload-file-button-" + index).files;
    const formData = new FormData();
    formData.append('excel', files[0]);
    formData.append('DEN_DISC', document.getElementById("DEN_DISC-upload-" + index).value);
    formData.append('PREFIX', document.getElementById("PREFIX-upload-" + index).value);
    formData.append('COD_SPEC', document.getElementById("COD_SPEC-upload-" + index).value);
    formData.append('EVALUARE', document.getElementById("EVALUARE-upload-" + index).value);

    xhr.send(formData);
  }

  function handleClickIstoricIncarcari(DEN_DISC, PREFIX, COD_SPEC, EVALUARE) {
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/istoric", true);

    xhr.onload = function() {
      setIstoricIncarcariJson(JSON.parse(xhr.response));
      setOpenIstoricIncarcari(true);
      el.innerHTML = "";
      setLoading(!isLoading);
    }

    const formData = new FormData();
    formData.append('DEN_DISC', DEN_DISC);
    formData.append('PREFIX', PREFIX);
    formData.append('COD_SPEC', COD_SPEC);
    formData.append('EVALUARE', EVALUARE);

    xhr.send(formData);
  }

  function handleClickPrevizualizareNote(DEN_DISC, PREFIX, COD_SPEC) {
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/get/note", true);

    xhr.onload = function() {
      setStudentiJson(JSON.parse(xhr.response));
      setOpenPrevizualizareNote(true);
      el.innerHTML = "";
      setLoading(!isLoading);
    }

    const formData = new FormData();
    formData.append('DEN_DISC', DEN_DISC);
    formData.append('PREFIX', PREFIX);
    formData.append('COD_SPEC', COD_SPEC);

    xhr.send(formData);
  }

  function handleClickDiferenteSincronizari(DEN_DISC, PREFIX, COD_SPEC, FACULTATE, EVALUARE, CALE_1) {
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/diferente", true);

    xhr.onload = function() {
      setDiferenteJson(JSON.parse(xhr.response));
      setOpenDiferenteSincronizari(true);
      el.innerHTML = "";
      setLoading(!isLoading);
    }

    var CALE_2 = "";
    var indexCALE_1 = istoricIncarcariJson.incarcari.findIndex((incarcare) => incarcare.cale == CALE_1);

    if(istoricIncarcariJson.incarcari[indexCALE_1 + 1].cale.includes("sincronizat_") || istoricIncarcariJson.incarcari[indexCALE_1 + 1].cale.includes("initial.xlsx")) {
      CALE_2 = istoricIncarcariJson.incarcari[indexCALE_1 + 1].cale;
    }
    else {
      CALE_2 = istoricIncarcariJson.incarcari[indexCALE_1 + 2].cale;
    }

    const formData = new FormData();
    formData.append('DEN_DISC', DEN_DISC);
    formData.append('PREFIX', PREFIX);
    formData.append('COD_SPEC', COD_SPEC);
    formData.append('FACULTATE', FACULTATE);
    formData.append('EVALUARE', EVALUARE);
    formData.append('CALE_1', CALE_1);
    formData.append('CALE_2', CALE_2);

    xhr.send(formData);
  }

  const handleClickOpen = (value, cadru) => {
    setDisciplinaSelectata(value);
    if(cadru != "") {
      for(var i = 0; i < cadreFiltrateJson.length; i++) {
        if(cadreFiltrateJson[i].email == cadru) {
          setCadruSelectat(cadreFiltrateJson[i]);
          break;
        }
      }
    }
  };

  const handleClose = () => {
    setAsociazaDisabled(true);
    setOpen(false);
    setCadruSelectat(null);
    setDisciplinaSelectata("");
  };

  const handleCloseErrorInfo = () => {
    setOpenErrorInfo(false);
  };

  const handleCloseIstoricIncarcari = () => {
    setOpenIstoricIncarcari(false);
    setIstoricIncarcariJson({materii: {}, incarcari: []});
  };

  const handleClosePrevizualizareNote = () => {
    setOpenPrevizualizareNote(false);
    setStudentiJson([]);
  };

  const handleCloseDiferenteSincronizari = () => {
    setOpenDiferenteSincronizari(false);
    setDiferenteJson([]);
  };

  //const fileName = materie.AN + "_" + materie.DEN_DISC + "_" + materie.PREFIX.replace(replacer1, '') + "_" + materie.COD_SPEC.replace(replacer1, '').replace(replacer2, '') + ".xlsx";
  return (
    <div className="MateriiSecretariat">
      {/* <Button
        variant="contained"
        className={classes.logoutButton}
        size="large"
        startIcon={<ExitToAppIcon />}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        Deconectează-te
      </Button> */}
      {/* <IconButton 
        component="span"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon fontSize="large" />
      </IconButton> */}
      <Fab 
        size="large"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon />
      </Fab>
      <h2 className={classes.margin}>Listă discipline la specializarea "{profilCurent.denumire_profil != undefined ? (profilCurent.denumire_profil + " - " + profilCurent.cod_profil + "_" + profilCurent.cod_specializare) : ""}"</h2>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<SaveIcon />}
        onClick={(e) => {
          e.preventDefault();
          materieDownloadClick();
          window.location.href='/materii/downloadCoursesList';
        }}
        disabled={loadingControlDisabled}
      >
        Save
      </Button>
      <br></br>
      <FormControl className={classes.formControl}>
        <InputLabel>An</InputLabel>
        <Select
          id="anSelectFilter"
          onChange={(e) => handleAnSelectFilterSelected(e)}
          disabled={loadingControlDisabled}
        >
          <MenuItem value=''>Toti</MenuItem>
          {aniStudiu.map((an) => (
            <MenuItem value={an}>{an}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Semestrul</InputLabel>
        <Select
          id="semestruSelectFilter"
          onChange={(e) => handleSemestruSelectFilterSelected(e)}
          disabled={loadingControlDisabled}
        >
          <MenuItem value=''>Ambele</MenuItem>
          {[1, 2].map((semestru) => (
            <MenuItem value={semestru}>{semestru}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className={classes.formControl}
        id="standard-basic"
        label="Caută"
        name="textFilter"
        onChange={(e) => handleTextFilterChanged(e)}
        disabled={loadingControlDisabled}
      />

      <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell>An studiu</TableCell>
            <TableCell>Disciplina</TableCell>
            <TableCell>Prefix_Cod</TableCell>
            <TableCell>Evaluare</TableCell>
            <TableCell>
              <div className={classes.margin}>
                Cadru didactic
              </div>
            </TableCell>
            <TableCell>
              <div className={classes.margin}>
                Încărcare Catalog
              </div>
            </TableCell>
            <TableCell>
              {"Ultima sincronizare"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materiiFiltrateJson.map((materie, index) => ( //materie.PREFIX.replace(replacer1, '') + "_" + materie.COD_SPEC.replace(replacer1, '').replace(replacer2, '')
            <TableRow>
              <TableCell><center>{materie.AN}</center></TableCell>
              <TableCell>
                <Tooltip title="Previzualizare note">
                  <Fab size="small" color="primary" aria-label="previzualizare-note" className={classes.margin} onClick={() => handleClickPrevizualizareNote(materie.DEN_DISC, materie.PREFIX, materie.COD_SPEC)}>
                    <VisibilityIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Istoric încărcări">
                  <Fab size="small" color="primary" aria-label="istoric-incarcari" className={classes.margin} onClick={() => handleClickIstoricIncarcari(materie.DEN_DISC, materie.PREFIX, materie.COD_SPEC, materie.EVALUARE)}>
                    <HistoryIcon />
                  </Fab>
                </Tooltip>
                <img src={xlsxLogo} className={classes.xlsxLogo} />
                <a href="" onClick={(e) => handleMaterieDownloadClick(e, materie.DEN_DISC, materie.PREFIX, materie.COD_SPEC, materie.AN, materie.VALABILITATE, materie.EVALUARE, (materie.CADRU_DIDACTIC != undefined ? materie.CADRU_DIDACTIC.nume : ""))}>{materie.DEN_DISC + ((materie.VALABILITATE != "2023-2024" && materie.VALABILITATE != "-") ? ` (${materie.VALABILITATE})` : "")}</a>
              </TableCell>
              <TableCell>
                {(materie.AN != null && !materie.PREFIX.includes(profilCurent.cod_profil + "." + profilCurent.cod_specializare)) ?
                  (<div style={{color: 'orange'}}>{materie.PREFIX + "_" + materie.COD_SPEC}</div>)
                  :
                  (materie.PREFIX + "_" + materie.COD_SPEC)
                }
              </TableCell>
              <TableCell>
                <Select
                  labelId={"departament-select-label-" + index}
                  value={(materie.hasOwnProperty("EVALUARE") ? materie.EVALUARE : 0)}
                  // defaultValue={selectedFacultate}
                  onChange={(e) => handleEvaluareChanged(materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC + "__" + e.target.value)}
                >
                  {[{val: 0, nume: "Notă"},{val: 1, nume: "Calificativ"}].map((optiune) => (
                      <MenuItem value={optiune.val}>{optiune.nume}</MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                {materie.AN == null ? 
                  (materie.hasOwnProperty("CADRU_DIDACTIC") ? (
                    <div>
                      <ColorFab size="small" color="secondary" aria-label="delete-asociere" className={classes.margin} onClick={() => handleDeleteAsociere(materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC)}>
                        <DeleteIcon />
                      </ColorFab>
                      {(materie.CADRU_DIDACTIC != undefined ? materie.CADRU_DIDACTIC.email : "")}
                    </div>) : "")
                  :
                  (materie.hasOwnProperty("CADRU_DIDACTIC") ? (
                    <div>
                      <Fab size="small" color="primary" aria-label="edit" className={classes.margin} onClick={() => handleClickOpen(materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC, (materie.CADRU_DIDACTIC != undefined ? materie.CADRU_DIDACTIC.email : ""))}>
                        <EditIcon />
                      </Fab>
                      {(materie.CADRU_DIDACTIC != undefined ? materie.CADRU_DIDACTIC.email : "")}
                    </div>)
                  :
                    (<Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => handleClickOpen(materie.PREFIX + "__" + materie.COD_SPEC + "__" + materie.DEN_DISC, "")}
                    >
                      Asociere
                    </Button>))
                }
              </TableCell>
              <TableCell> 
              <form id={"uploadForm-" + index} action="/upload" method="POST" enctype="multipart/form-data">
                <input type="text" value={materie.DEN_DISC} name="DEN_DISC" id={"DEN_DISC-upload-" + index} hidden />
                <input type="text" value={materie.PREFIX} name="PREFIX" id={"PREFIX-upload-" + index} hidden />
                <input type="text" value={materie.COD_SPEC} name="COD_SPEC" id={"COD_SPEC-upload-" + index} hidden />
                <input type="text" value={materie.EVALUARE} name="EVALUARE" id={"EVALUARE-upload-" + index} hidden />
                <Tooltip title="Selectați fișierul xlsx">
                  <span>
                    <input
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      className={classes.input}
                      name="excel"
                      id={"upload-file-button-" + index}
                      type="file"
                      disabled={!uploadEnabled}
                      onChange={() => handleUploadFileChange(index)}
                    />
                    <label htmlFor={"upload-file-button-" + index}>
                      <Button
                        variant="contained" 
                        color="primary"
                        className={classes.button}
                        startIcon={<PublishIcon />}
                        component="span"
                        disabled={!uploadEnabled}
                      >
                        Încarcă
                      </Button>
                    </label>
                  </span>
                </Tooltip>
              </form>
              </TableCell>
              <TableCell>
                {(materie.LAST_SYNC == undefined || materie.LAST_SYNC == null) ? "-" : <div>{materie.LAST_SYNC.nume}<br />{materie.LAST_SYNC.data}</div>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <SimpleDialog cadre={cadreJson} disciplinaSelectata={disciplinaSelectata} open={open} onClose={handleClose} /> */}
      <Dialog TransitionComponent={Transition} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{"Asociere disciplina \"" + disciplinaSelectata.split("__")[2] + "\""}</DialogTitle>
        <center>
          <form action="/materii/asign" method="POST" onSubmit={(e) => handleOnSubmitAsociaza(e)} noValidate>
            <input type="text" value={disciplinaSelectata} name="DISCIPLINA" id="DISCIPLINA" hidden />
            <input type="text" value={cadruSelectat} name="CADRU" id="CADRU" hidden />
            {/* <FormControl className={classes.formControl}>
              <InputLabel id="departament-select-label">Departament</InputLabel>
              <Select
                labelId="departament-select-label"
                // value={selectedFacultate}
                // defaultValue={selectedFacultate}
                onChange={(e) => handleDepartamentSelectFilterChanged(e)}
              >
                <MenuItem value=''>None</MenuItem>
                {departamente.map((departament) => (
                    <MenuItem value={departament}>{departament}</MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <Autocomplete
              id="cadru-select"
              value={cadruSelectat}
              onChange={(event, cadruDidactic) => {
                // console.log(cadruDidactic)
                setAsociazaDisabled(true);
                
                setCadruSelectat(cadruDidactic);
              }}
              options={cadreFiltrateJson}
              getOptionLabel={(cadruDidactic) => (cadruDidactic.nume + " - " + cadruDidactic.email)}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Cadrul Didactic" />}
            />
            <br></br>
            {/* <FormControl className={classes.formControl}>
              <InputLabel id="profil-select-label">Domeniul</InputLabel>
              <Select
                labelId="profil-select-label"
                name="PROFIL"
                value={selectedProfil}
                onChange={(e) => handleProfilSelect(e)}
              >
                {profileJson.map((profil) => (
                    <MenuItem value={profil.denumire_profil}>{profil.denumire_profil}</MenuItem>
                ))}
              </Select>
            </FormControl>  */}
            <br></br>
            <Button
              type="Submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={asociazaDisabled}
            >
              Asociază
            </Button>
            
          </form>
        </center>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        onClose={handleCloseErrorInfo}
        aria-labelledby="error-info-title"
        aria-describedby="error-info-description"
        open={openErrorInfo}>
        <DialogTitle id="error-info-title">{"Eroare"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-info-description">
            {errorInfoDescription}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        fullWidth="true"
        maxWidth="md"
        aria-labelledby="istoric-incarcari-title" 
        aria-describedby="istoric-incarcari-description"
        open={openIstoricIncarcari}
        onClose={handleCloseIstoricIncarcari}
      >
        <DialogTitle id="istoric-incarcari-title">{"Istoric încărcări"}</DialogTitle>
        <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell><b>Denumire</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {istoricIncarcariJson.incarcari.map(incarcare => 
              <TableRow>
                <TableCell>
                  <a href="" onClick={(e) => handleIstoricDownloadClick(e, incarcare.cale)}>{incarcare.cale.split("/")[5]}</a>
                  {incarcare.differences && 
                    <Fab size="small" color="primary" aria-label="diferente-incarcari" className={classes.margin} onClick={() => handleClickDiferenteSincronizari(istoricIncarcariJson.materie.DEN_DISC, istoricIncarcariJson.materie.PREFIX, istoricIncarcariJson.materie.COD_SPEC, istoricIncarcariJson.materie.FACULTATE, istoricIncarcariJson.materie.EVALUARE, incarcare.cale)}>
                      <SwapVerticalCircleIcon />
                    </Fab>}
                  
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleCloseIstoricIncarcari} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        fullWidth="true"
        maxWidth="lg"
        aria-labelledby="previzualizare-note-title" 
        aria-describedby="previzualizare-note-description"
        open={openPrevizualizareNote}
        onClose={handleClosePrevizualizareNote}
      >
        <DialogTitle id="previzualizare-note-title">{"Note"}</DialogTitle>
        <Table stickyHeader fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell><b>Nr. crt.</b></TableCell>
              <TableCell><b>Marca</b></TableCell>
              <TableCell><b>Grupa</b></TableCell>
              <TableCell><b>Numele și prenumele</b></TableCell>
              <TableCell><b>P1</b></TableCell>
              <TableCell><b>P2</b></TableCell>
              <TableCell><b>P3</b></TableCell>
              <TableCell><b>Nota activitate</b></TableCell>
              <TableCell><b>Nota finală</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentiJson.map((student, index) => 
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{student.MARCA}</TableCell>
                <TableCell>{student.GR_ADM}</TableCell>
                <TableCell>{student.NUME}</TableCell>
                <TableCell><div className={classes.notaText}>{student.PREZ1 != 0 ? student.PREZ1 : <span>&nbsp;</span>}</div></TableCell>
                <TableCell><div className={classes.notaText}>{student.PREZ2 != 0 ? student.PREZ2 : <span>&nbsp;</span>}</div></TableCell>
                <TableCell><div className={classes.notaText}>{student.PREZ3 != 0 ? student.PREZ3 : <span>&nbsp;</span>}</div></TableCell>
                <TableCell><div className={classes.notaText}>{student.NP != 0 ? student.NP : <span>&nbsp;</span>}</div></TableCell>
                <TableCell><div className={classes.notaText}>{student.NOTA != 0 ? student.NOTA : <span>&nbsp;</span>}</div></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleClosePrevizualizareNote} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        fullWidth="true"
        maxWidth="lg"
        aria-labelledby="diferente-sincronizari-title" 
        aria-describedby="diferente-sincronizari-description"
        open={openDiferenteSincronizari}
        onClose={handleCloseDiferenteSincronizari}
      >
        <DialogTitle id="diferente-sincronizari-title">{"Diferențe față de sincronizarea anterioară"}</DialogTitle>
        <div>
          <div className={classes.square} style={{display: 'inline-block', background: bgColors["a"]}} />
          {` adăugat`}
        </div>
        <br />
        <div>
          <div className={classes.square} style={{display: 'inline-block', background: bgColors["r"]}} />
          {` șters`}
        </div>
        <br />
        <div>
          <div className={classes.square} style={{display: 'inline-block', background: bgColors["m"]}} />
          {` modificat`}
        </div>
        <br />
        <Table stickyHeader fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell><b>Nr. crt.</b></TableCell>
              <TableCell><b>Marca</b></TableCell>
              <TableCell><b>Grupa</b></TableCell>
              <TableCell><b>Numele și prenumele</b></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes.textAlignRight}><b>P1</b></TableCell>
              <TableCell className={classes.textAlignRight}><b>P2</b></TableCell>
              <TableCell className={classes.textAlignRight}><b>P3</b></TableCell>
              <TableCell className={classes.textAlignRight}><b>Nota activitate</b></TableCell>
              <TableCell className={classes.textAlignRight}><b>Nota finală</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diferenteJson.map((student, index) => 
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{student.MARCA}</TableCell>
                <TableCell>{student.GR_ADM}</TableCell>
                <TableCell>{student.NUME}</TableCell>
                <TableCell><div>Note noi<br />Note anterioare</div></TableCell>
                <TableCell className={classes.notaCell}>
                  <div style={{background: bgColors[student.P1_d]}}>
                    <div className={classes.notaText}>{student.P1_1 != 0 ? student.P1_1 : <span>&nbsp;</span>}</div>
                    <div className={classes.notaText}>{student.P1_2 != 0 ? student.P1_2 : <span>&nbsp;</span>}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.notaCell}>
                  <div style={{background: bgColors[student.P2_d]}}>
                    <div className={classes.notaText}>{student.P2_1 != 0 ? student.P2_1 : <span>&nbsp;</span>}</div>
                    <div className={classes.notaText}>{student.P2_2 != 0 ? student.P2_2 : <span>&nbsp;</span>}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.notaCell}>
                  <div style={{background: bgColors[student.P3_d]}}>
                    <div className={classes.notaText}>{student.P3_1 != 0 ? student.P3_1 : <span>&nbsp;</span>}</div>
                    <div className={classes.notaText}>{student.P3_2 != 0 ? student.P3_2 : <span>&nbsp;</span>}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.notaCell}>
                  <div style={{background: bgColors[student.NP_d]}}>
                    <div className={classes.notaText}>{student.NP_1 != 0 ? student.NP_1 : <span>&nbsp;</span>}</div>
                    <div className={classes.notaText}>{student.NP_2 != 0 ? student.NP_2 : <span>&nbsp;</span>}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.notaCell}>
                  <div style={{background: bgColors[student.NF_d]}}>
                    <div className={classes.notaText}>{student.NF_1 != 0 ? student.NF_1 : <span>&nbsp;</span>}</div>
                    <div className={classes.notaText}>{student.NF_2 != 0 ? student.NF_2 : <span>&nbsp;</span>}</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleCloseDiferenteSincronizari} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  ); 
}

export default MateriiSecretariat;
