import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import xlsxLogo from '../xlsx.svg';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';

import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import PublishIcon from '@material-ui/icons/Publish';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetApp  from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import { orange, green, red } from '@material-ui/core/colors';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  xlsxLogo: {
    marginRight: theme.spacing(1),
    height: 16,
    width: 16,
  },
  dialogDescription: {
    margin: theme.spacing(1),
    textAlign: "justify",
  },
  logoutButton: {
    position: "absolute",
    right: 16,
    top: 116,
  },
  errorInfoDialog: {
    position: "absolute",
    top: theme.spacing(2),
  },
  paperWidth: {
    width: "fit-content",
  },
  rightAlignText: {
    textAlign: "right",
  },
}));

function expireCookie( cName ) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}


function AdminConfigUpload() {
  const [isLoading, setLoading] = useState(true);
  const [uploadEnabled, setUploadEnabled] = React.useState({
    secretariate: false,
    cadreSem1: false,
    cadreSem2: false,
  });

  const classes = useStyles();

  useEffect(() => {
    fetch("/get/configUpload")
        .then((response) => response.json())
        .then(rJson => {
          // console.log(mJson);
          setUploadEnabled(rJson);
          const el = document.querySelector(".loader-container");
          if (el) {
            el.innerHTML = "";
            setLoading(!isLoading);
          }
        });

  }, []);

  const handleChange = (event) => {
    var copyUploadEnabled = {...uploadEnabled};
    copyUploadEnabled[event.target.name] = event.target.checked;

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/admin/config/upload", true);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.send(JSON.stringify({
      upload: copyUploadEnabled,
    }));

    setUploadEnabled({ ...uploadEnabled, [event.target.name]: event.target.checked });
  };


  return (
    <div className="AdminConfigUpload">
      <Fab 
        size="large"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon />
      </Fab>
      <h2 className={classes.margin}>Configurare încărcări</h2>

      <FormControlLabel
        className={classes.margin}
        control={
          <Switch
            checked={uploadEnabled.secretariate}
            onChange={handleChange}
            name="secretariate"
            color="primary"
          />
        }
        label="Încărcare secretariate"
      />
      <br />
      <FormControlLabel
        className={classes.margin}
        control={
          <Switch
            checked={uploadEnabled.cadreSem1}
            onChange={handleChange}
            name="cadreSem1"
            color="primary"
          />
        }
        label="Încărcare cadre semestrul 1"
      />
      <FormControlLabel
        className={classes.margin}
        control={
          <Switch
            checked={uploadEnabled.cadreSem2}
            onChange={handleChange}
            name="cadreSem2"
            color="primary"
          />
        }
        label="Încărcare cadre semestrul 2"
      />

    </div>
  ); 
}

export default AdminConfigUpload;
