import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import xlsxLogo from '../xlsx.svg';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import PublishIcon from '@material-ui/icons/Publish';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import { orange, purple } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  xlsxLogo: {
    marginRight: theme.spacing(1),
    height: 16,
    width: 16,
  },
  dialogDescription: {
    margin: theme.spacing(1),
    textAlign: "justify",
  },
  logoutButton: {
    position: "absolute",
    right: 16,
    top: 116,
  },
  finalizeazaButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  tableDiv: {
    position: "relative",
    width: "fit-content"
  },
  errorInfoDialog: {
    position: "absolute",
    top: theme.spacing(2),
  },
  nota: {
    marginBottom: theme.spacing(3),
  },
  bgWhite: {
    background: "white",
  },
  bgAliceBlue: {
    background: "aliceblue"
  },
  bgCoral: {
    background: "coral"
  },
  notaCell: {
    padding: 0,
  },
  notaText: {
    marginRight: theme.spacing(2),
    textAlign: "right",
  },
  paperWidth: {
    width: "fit-content",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Button);

var attempts = 40;
var downloadTimer;
var firstTime = true;
var sortRequired = true;

function expireCookie( cName ) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function getCookie( name ) {
  var parts = document.cookie.split(name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UploadPreview() {
  const [studentiValidJson, setStudentiValidJson] = useState([]);
  const [studentiInvalidJson, setStudentiInvalidJson] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [openInfoAtentionari, setOpenInfoAtentionari] = useState(false);
  const [openInfoNote, setOpenInfoNote] = useState(false);
  const [openConfirmConflicts, setOpenConfirmConflicts] = useState(false);
  const [openConfirmSincronizare, setOpenConfirmSincronizare] = useState(false);
  const [openInformareSincronizare, setOpenInformareSincronizare] = useState(false);
  const [openInformareNeincarcati, setOpenInformareNeincarcati] = useState(false);
  const [openConfirmConflictsInfo, setOpenConfirmConflictsInfo] = useState(false);
  const [errorInfoDescription, setErrorInfoDescription] = useState("");
  const [selectedSortare, setSelectedSortare] = useState(0);
  const [validStudenti, setValidStudenti] = useState(0);
  const [totalStudenti, setTotalStudenti] = useState(0);
  const [denumireMaterie, setDenumireMaterie] = useState("");
  const [ponderi, setPonderi] = useState([]);
  const [confirmConflictsDisabled, setConfirmConflictsDisabled] = useState(true);
  const [confirmSincronizareDescription, setConfirmSincronizareDescription] = useState(-1);

  

  const classes = useStyles();

  useEffect(() => {
    if(studentiValidJson.length == 0 && studentiInvalidJson.length == 0) {
      fetch("/get/studenti")
        .then((response) => response.json())
        .then(mJson => {
          var studentiValid = [];
          var studentiInvalid = [];
          var validNr = 0;
          var sJson = mJson.studenti;
          sJson.forEach(student => {
            if(student.valid) {
              validNr++;
              student.confirmConflict = false;
              studentiValid.push(student);
            }
            else {
              studentiInvalid.push(student);
            }
          });
          setValidStudenti(validNr);
          setTotalStudenti(sJson.length);
          setStudentiValidJson(studentiValid);
          setStudentiInvalidJson(studentiInvalid);

          setDenumireMaterie(mJson.denumire);

          setPonderi([mJson.K1, mJson.K2]);


          const el = document.querySelector(".loader-container");
          if (el) {
            el.innerHTML = "";
            setLoading(!isLoading);
          }
        });
    }
    
  }, []);

  useEffect(() => {
    if(errorInfoDescription != "") {
      setOpenErrorInfo(true);
    }
  }, [errorInfoDescription]);

  useEffect(() => {
    if(openErrorInfo == false) {
      setErrorInfoDescription("");
    }
  }, [openErrorInfo]);

  
  useEffect(() => {
    if(studentiValidJson.length != 0 && firstTime) {
      firstTime = false;
      handleSortareChanged(0);
    }

  }, [studentiValidJson]);

  useEffect(() => {
    if(studentiInvalidJson.length != 0) {
      setOpenInformareNeincarcati(true);
    }
  }, [studentiInvalidJson]);

  // useEffect(() => {
  //   if(confirmSincronizareDescription != -1)
  //     setOpenConfirmSincronizare(true);
  // }, [confirmSincronizareDescription]);


  const handleCloseAtentionariInfo = () => {
    setOpenInfoAtentionari(false);
  };

  const handleCloseNoteInfo = () => {
    setOpenInfoNote(false);
  };

  const handleCloseConfirmConflicts = () => {
    setOpenConfirmConflicts(false);
  };

  const handleConfirmConflicts = () => {
    studentiValidJson.forEach(student => {
      if(student.conflict == true && student.confirmConflict == true) {
        student.conflict = false;
        student.confirmConflict = false;
      }
    })

    if(sortRequired == true) {
      studentiValidJson.sort(function(a, b) {
        var x = ('' + b.conflict) + ('' + a.grAdm + a.nume);
        var y = ('' + a.conflict) + ('' + b.grAdm + b.nume);
    
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });

      // setStudentiValidJson(studenti);
    }

    setConfirmConflictsDisabled(true);
    handleCloseConfirmConflicts();
  };

  const handleCloseConfirmSincronizare = () => {
    setOpenConfirmSincronizare(false);
  };

  const handleConfirmSincronizare = () => {
    handleCloseConfirmSincronizare();
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);
    // console.log(document.getElementById("utilizator").value);
    // console.log(document.getElementById("parola").value);


    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/upload/submit", true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = function() {
      
      if(xhr.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        var blob = new Blob([xhr.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;

        var fileName = xhr.getResponseHeader('Content-Disposition');
        fileName = fileName.includes("filename*=UTF-8''") ? fileName.split("filename*=UTF-8''")[1] : fileName.split("filename=\"")[1].substring(0, fileName.split("filename=\"")[1].length - 1);
        
        fileName = decodeURIComponent(fileName);

        a.download = fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);
      }
      expireCookie("downloaded");
      el.innerHTML = "";
      setLoading(!isLoading);


      setOpenInformareSincronizare(true);
      // if(response.success == true) {
      //   if(response.data == "secretariat")
      //     window.location.href='/home';
      //   else if(response.data == "didactic")
      //     window.location.href='/materii/lista-materii-cd';
      // }
      // else {
        
      //   setErrorInfoDescription(response.message);
      // }
      
    }

    xhr.send(JSON.stringify({
      studenti: JSON.stringify(studentiValidJson),
    }));
  };

  const handleInformareSincronizare = () => {
    // console.log(document.referrer);
    window.location.href = document.referrer;
  };

  const handleCloseInformareNeincarcati = () => {
    setOpenInformareNeincarcati(false);
  };

  function handleCheckedChange(e, index) {
    studentiValidJson[index].confirmConflict = e.target.checked;
    
    var disabled = true;
    if(e.target.checked == true) {
      disabled = false;
    }
    else {
      studentiValidJson.forEach(student => {
        if(student.confirmConflict == true)
          disabled = false;
      });
    }

    setConfirmConflictsDisabled(disabled);
    setStudentiValidJson([...studentiValidJson]);
  }

  function handleCheckedAllChange(e) {
    var foundConflict = false;
    studentiValidJson.forEach(student => {
      if(student.conflict) {
        student.confirmConflict = e.target.checked;
        foundConflict = true
      }
    })

    if(foundConflict)
      setConfirmConflictsDisabled(!e.target.checked);
    setStudentiValidJson([...studentiValidJson]);
  }


  function fix0(nota){
    if(nota != 0)
      return nota;
  }

  function handleSortareChanged(option) {
    var studenti = studentiValidJson.slice();

    if(option == 0) {
      sortRequired = true;
      studenti.sort(function(a, b) {
        var x = b.conflict;
        var y = a.conflict;
    
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    }
    else {
      sortRequired = false;
      studenti.sort(function(a, b) {
        var x = a.nrCrt;
        var y = b.nrCrt;
    
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    }
    
    setSelectedSortare(option);
    setStudentiValidJson(studenti);
  }

  const handleClickInfoAtentionari = () => {
    setOpenInfoAtentionari(true);
  };

  const handleClickInfoNote = () => {
    setOpenInfoNote(true);
  };

  const handleClickConfirmConflictsInfo = () => {
    setOpenConfirmConflictsInfo(true);
  };

  const handleCloseConfirmConflictsInfo = () => {
    setOpenConfirmConflictsInfo(false);
  };

  const handleConfirmConflictsClick = () => {
    setOpenConfirmConflicts(true);
  }

  const handleConfirmSincronizareClick = () => {
    setConfirmSincronizareDescription(-1);
    var conflictFound = false;
    for(var i = 0; i < studentiValidJson.length; i++) {
      if(studentiValidJson[i].conflict == true) {
        conflictFound = true;
        break;
      }
    }

    if(conflictFound)
      setConfirmSincronizareDescription(1);
    else
      setConfirmSincronizareDescription(0);

    setOpenConfirmSincronizare(true);
  }

  return (
    <div className="UploadPreview">
      <h2 className={classes.margin}>{`${denumireMaterie || ""}`}</h2>

      <Paper className={classes.paperWidth}>
        <div style={{display: "block", fontSize: "1.17em", marginTop: "1em", marginBottom: "1em", marginLeft: "0", marginRight: "0"}}>
          {`Studenți încărcați cu succes: `} <b>{(validStudenti || "0")}</b> {` din `} <b>{(totalStudenti || "0")}</b>
          <Fab size="small" color="primary" aria-label="help-sync" className={classes.margin} onClick={() => handleClickInfoAtentionari()}>
            <InfoIcon />
          </Fab>
          <br />
          K1 = <b>{ponderi[0]}</b><br />
          K2 = <b>{ponderi[1]}</b>
        </div>
      </Paper>
        
      <br />
      <FormControl className={classes.formControl}>
        <InputLabel>Ordonează</InputLabel>
        <Select
          labelId={"sortare-select-label"}
          defaultValue={selectedSortare}
          onChange={(e) => handleSortareChanged(e.target.value)}
        >
          {[{val: 0, nume: "Conflicte"},{val: 1, nume: "Implicit"}].map((optiune) => (
              <MenuItem value={optiune.val}>{optiune.nume}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.tableDiv}>
        <ColorButton
          disabled={confirmConflictsDisabled}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleConfirmConflictsClick}
        >
        {"Confirmă conflicte"}
        </ColorButton>
        <Fab size="small" color="primary" aria-label="help-sync" className={classes.margin} onClick={() => handleClickConfirmConflictsInfo()}>
          <InfoIcon />
        </Fab>

        <Button
          variant="contained"
          color="primary"
          className={classes.finalizeazaButton}
          onClick={handleConfirmSincronizareClick}
        >
          Finalizare
        </Button>
        {/* <input type="button" className={classes.confirmConflictsButton}></input> */}
        
        <Table stickyHeader fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  color="primary"
                  onChange={e =>handleCheckedAllChange(e)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
              <TableCell><b>Nr. crt.</b></TableCell>
              <TableCell><b>Marca</b></TableCell>
              <TableCell><b>Grupa</b></TableCell>
              <TableCell><b>Numele și prenumele</b></TableCell>
              <TableCell>
                <Fab size="small" color="primary" aria-label="help-sync" className={classes.margin} onClick={() => handleClickInfoNote()}>
                  <InfoIcon />
                </Fab>
              </TableCell>
              <TableCell><b>Prez. 1</b></TableCell>
              <TableCell><b>Prez. 2</b></TableCell>
              <TableCell><b>Prez. 3</b></TableCell>
              <TableCell><b>NP</b></TableCell>
              <TableCell><b>Nota finală</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentiValidJson.map((student, index) => (
              <TableRow className={(index % 2 ? classes.bgWhite : classes.bgAliceBlue)}>
                <TableCell>
                  {student.conflict &&
                  <Checkbox
                    color="primary"
                    checked={student.confirmConflict}
                    onChange={e =>handleCheckedChange(e, index)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  } 
                </TableCell>
                <TableCell>{student.nrCrt}</TableCell>
                <TableCell>{student.marca}</TableCell>
                <TableCell>{student.grAdm}</TableCell>
                <TableCell>{student.nume}</TableCell>
                <TableCell>{student.conflict && (<div>Note XLSX<br />Note anterioare</div>)}</TableCell>
                <TableCell className={classes.notaCell}>
                  <div className={classes.notaText}>{student.P1_new || <span>&nbsp;</span>}</div>
                  {student.conflict && 
                  <div style={{background: "orange"}}>
                    <div className={classes.notaText}>
                      {student.P1_old == "0" ? <span>&nbsp;</span> : fix0(student.P1_old)}
                    </div>
                  </div>}
                </TableCell>
                <TableCell className={classes.notaCell}>
                  <div className={classes.notaText}>{student.P2_new || <span>&nbsp;</span>}</div>
                  {student.conflict && 
                  <div style={{background: "orange"}}>
                    <div className={classes.notaText}>
                      {student.P2_old == "0" ? <span>&nbsp;</span> : fix0(student.P2_old)}
                    </div>
                  </div>}
                </TableCell>
                <TableCell className={classes.notaCell}>
                  <div className={classes.notaText}>{student.P3_new || <span>&nbsp;</span>}</div>
                  {student.conflict && 
                  <div style={{background: "orange"}}>
                    <div className={classes.notaText}>
                      {student.P3_old == "0" ? <span>&nbsp;</span> : fix0(student.P3_old)}
                    </div>
                  </div>}
                </TableCell>
                <TableCell className={classes.notaCell}>
                  <div className={classes.notaText}>{student.NP_new || <span>&nbsp;</span>}</div>
                  {student.conflict && 
                  <div style={{background: "orange"}}>
                    <div className={classes.notaText}>
                      {student.NP_old == "0" ? <span>&nbsp;</span> : fix0(student.NP_old)}
                    </div>
                  </div>}
                </TableCell>
                <TableCell className={classes.notaCell}>
                  <div className={classes.notaText}>{student.nota_new || <span>&nbsp;</span>}</div>
                  {student.conflict && 
                  <div style={{background: "orange"}}>
                    <div className={classes.notaText}>
                      {student.nota_old == "" ? <span>&nbsp;</span> : fix0(student.nota_old)}
                    </div>
                  </div>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>


      <Dialog 
        TransitionComponent={Transition}
        fullWidth="true"
        maxWidth="md"
        onClose={handleCloseAtentionariInfo}
        aria-labelledby="simple-dialog-atentionari-info" 
        open={openInfoAtentionari}
      >
        <DialogTitle id="simple-dialog-atentionari-info">{"Atenționări"}</DialogTitle>
        <Table stickyHeader fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell><b>Nr. crt.</b></TableCell>
              <TableCell><b>Marca</b></TableCell>
              <TableCell><b>Numele și prenumele</b></TableCell>
              <TableCell><b>Motiv</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentiInvalidJson.map(student => 
              <TableRow>
                <TableCell>{student.nrCrt}</TableCell>
                <TableCell>{student.marca}</TableCell>
                <TableCell>{student.nume}</TableCell>
                <TableCell>{student.motiv}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleCloseAtentionariInfo} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        onClose={handleCloseNoteInfo}
        aria-describedby="note-info-description"
        open={openInfoNote}
      >
        <DialogContent>
          <DialogContentText id="note-info-description">
            <b>note XLSX:</b> notele existente în catalogul de note pe care tocmai l-ați încărcat<br />
            <br />
            <b>note anterioare:</b> notele existente în baza de date provenite din încărcări anterioare
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoteInfo} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        onClose={handleCloseConfirmConflictsInfo}
        aria-describedby="confirm-conflicts-info-description"
        open={openConfirmConflictsInfo}
      >
        <DialogContent>
          <DialogContentText id="confirm-conflicts-info-description">
            Diferențele/conflictele între notele anterioare și notele din XLS-ul pe care l-ați încărcat sunt semnalate prin marcarea cu portocaliu a notelor anterioare.<br /><br />
            În caz de conflict/diferență aveți opțiunea de a suprascrie notele anterioare cu cele noi introduse prin selectarea studenților în cauză și apăsarea butonului <b>CONFIRMĂ CONFLICTE</b>.<br /><br />
            Dacă se dorește păstrarea notelor anterioare conflictele nu vor fi confirmate și se va apăsa butonul de <b>FINALIZARE</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmConflictsInfo} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        onClose={handleCloseConfirmConflicts}
        aria-labelledby="confirm-conflicts-title" 
        aria-describedby="confirm-conflicts-description"
        open={openConfirmConflicts}
      >
        <DialogTitle id="confirm-conflicts-title">{"Confirmare"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-conflicts-description">
            {`Doriți să suprascrieți vechile note selectate?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmConflicts} color="primary">
            Da
          </Button>
          <Button onClick={handleCloseConfirmConflicts} color="primary">
            Nu
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        onClose={handleCloseConfirmSincronizare}
        aria-labelledby="confirm-sincronizare-title" 
        aria-describedby="confirm-sincronizare-description"
        open={openConfirmSincronizare}
      >
        <DialogTitle id="confirm-sincronizare-title">{"Confirmare"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-sincronizare-description">
            {confirmSincronizareDescription == 0 ? 
            <div>Doriți să sincronizați notele?</div>
            :
            <div>Aveți conflicte <b>neconfirmate</b> între note anterioare și notele curente. Sincronizarea nu va lua în calcul notele curente dacă nu confirmați conflctele.<br /><br />Continuați sincronizarea?</div>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmSincronizare} color="primary">
            Da
          </Button>
          <Button onClick={handleCloseConfirmSincronizare} color="primary">
            Nu
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        aria-labelledby="informare-sincronizare-title" 
        aria-describedby="informare-sincronizare-description"
        open={openInformareSincronizare}
      >
        <DialogTitle id="informare-sincronizare-title">{"Informare"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="informare-sincronizare-description">
            {`Datele au fost transmise cu succes.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInformareSincronizare} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        aria-labelledby="informare-neincarcati-title" 
        aria-describedby="informare-neincarcati-description"
        open={openInformareNeincarcati}
      >
        <DialogTitle id="informare-neincarcati-title">{"Următorii studenți nu au putut fi încărcați"}</DialogTitle>
        <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell><b>Nr. crt.</b></TableCell>
              <TableCell><b>Marca</b></TableCell>
              <TableCell><b>Numele și prenumele</b></TableCell>
              <TableCell><b>Motiv</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentiInvalidJson.map(student => 
              <TableRow>
                <TableCell>{student.nrCrt}</TableCell>
                <TableCell>{student.marca}</TableCell>
                <TableCell>{student.nume}</TableCell>
                <TableCell>{student.motiv}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleCloseInformareNeincarcati} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      
      
    </div>
  ); 
}

export default UploadPreview;
