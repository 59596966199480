import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import xlsxLogo from '../xlsx.svg';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';

import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import PublishIcon from '@material-ui/icons/Publish';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HistoryIcon from '@material-ui/icons/History';
import { orange } from '@material-ui/core/colors';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  xlsxLogo: {
    marginRight: theme.spacing(1),
    height: 16,
    width: 16,
  },
  dialogDescription: {
    margin: theme.spacing(1),
    textAlign: "justify",
  },
  logoutButton: {
    position: "absolute",
    right: 16,
    top: 116,
  },
  errorInfoDialog: {
    position: "absolute",
    top: theme.spacing(2),
  },
  pageDiv: {
    position: "relative",
    width: "fit-content"
  },
  tutorialDiv: {
    //position: "absolute",
    // right: theme.spacing(4),
    // top: theme.spacing(1),
    margin: theme.spacing(1)
  },
  notaText: {
    marginRight: theme.spacing(2),
    textAlign: "right",
  },
  infoDiv: {
    color: "orange",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 19
  },
  specializareDiv: {
    fontSize: 13
  },
}));

const ColorFab = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[800]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Fab);

var attempts = 40;
var downloadTimer;

function expireCookie( cName ) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function getCookie( name ) {
  var parts = document.cookie.split(name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MateriiSecretariat() {
  const [materiiJson, setMateriiJson] = useState([]);
  const [materiiFiltrateJson, setMateriiFiltrateJson] = useState([]);
  const [studentiJson, setStudentiJson] = useState([]);
  const [facultati, setFacultati] = useState([]);
  const [facultateFilter, setFacultateFilter] = useState("");
  const [semestruFilter, setSemestruFilter] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [openInfoMaterie, setOpenInfoMaterie] = useState(false);
  const [openPrevizualizareNote, setOpenPrevizualizareNote] = useState(false);
  const [openHelpSync, setOpenHelpSync] = useState(false);
  const [openHelpImport, setOpenHelpImport] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [openInfoImportExport, setOpenInfoImportExport] = useState(false);
  const [loadingControlDisabled, setLoadingControlDisabled] = useState(true);
  const [errorInfoDescription, setErrorInfoDescription] = useState("");

  const [infoMaterieDenumire, setInfoMaterieDenumire] = useState("");
  const [infoMaterieFacultate, setInfoMaterieFacultate] = useState("");
  const [infoMaterieProfil, setInfoMaterieProfil] = useState("");
  const [infoMaterieSpecializare, setInfoMaterieSpecializare] = useState("");
  const [infoImportExport, setInfoImportExport] = useState("");
  const [studentiProblema, setStudentiProblema] = useState([]);

  const [uploadEnabled, setUploadEnabled] = useState(false);
  // const [infoMaterieSincronizare, setInfoMaterieSincronizare] = useState("");

  const classes = useStyles();

  useEffect(() => {
    fetch("/get/materii")
      .then((response) => response.json())
      .then(mJson => {
        getFacultati(mJson);
        setMateriiJson(mJson);
        setMateriiFiltrateJson(mJson);
      });

    fetch("/get/configUpload")
    .then((response) => response.json())
    .then(rJson => {
      setUploadEnabled(rJson);
      setLoadingControlDisabled(false);
      const el = document.querySelector(".loader-container");
      if (el) {
        el.innerHTML = "";
        setLoading(!isLoading);
      }
    });
    
  }, []);

  useEffect(() => {
    if(errorInfoDescription != "") {
      setOpenErrorInfo(true);
    }
  }, [errorInfoDescription]);

  useEffect(() => {
    if(openErrorInfo == false) {
      setErrorInfoDescription("");
    }
  }, [openErrorInfo]);

  // useEffect(() => {
  //   if(infoImportExport != "") {
  //     setOpenInfoImportExport(true);
  //   }
  // }, [infoImportExport]);

  useEffect(() => {
    filterMaterii();
  }, [facultateFilter, semestruFilter]);

  function filterMaterii() {
    var materiiFiltrate = [];

    materiiJson.forEach((materie) => {
      if(('' + materie.facultate).includes(facultateFilter) && ('' + materie.semestru).includes(semestruFilter)) {
          materiiFiltrate.push(materie);
      }
    });

    setMateriiFiltrateJson(materiiFiltrate);
  }

  function handleSyncFileChange(index) {
    if(document.getElementById("sync-file-button-" + index).value == "") {
      return;
    }
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/syncCV", true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/json") {
        const blob = new Blob([xhr.response], {type : 'application/json'});
        var response = JSON.parse(await blob.text());
        

        if(response.success == true) {
          let bytes = new Uint8Array(response.data.excel.data.length);  //nu merge direct cu response.data.excel.data in constructorul de la Blob
          for (var i = 0; i < response.data.excel.data.length; i++) {
            bytes[i] = response.data.excel.data[i];
          }

          var excel = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          //Create a DOMString representing the blob and point the link element towards it
          let url = window.URL.createObjectURL(excel);
          a.href = url;
          a.download = document.getElementById("sync-file-button-" + index).files[0].name;
          //programatically click the link to trigger the download
          a.click();
          //release the reference to the file by revoking the Object URL
          window.URL.revokeObjectURL(url);
        
          setInfoImportExport(response.message);
          if(response.data.studentiProblema.length != 0) {
            setStudentiProblema(response.data.studentiProblema);
          }
          else {
            setStudentiProblema([]);
          }
          setOpenInfoImportExport(true);
        }
        else {
          setErrorInfoDescription(response.message);
        }
        
      }
      expireCookie("downloaded");
      el.innerHTML = "";
      document.getElementById("sync-file-button-" + index).value = "";
      setLoading(!isLoading);
      
      
    }

    const files = document.getElementById("sync-file-button-" + index).files;
    const formData = new FormData();
    formData.append('excel', files[0]);
    formData.append('DEN_DISC', document.getElementById("DEN_DISC-sync-" + index).value);
    formData.append('PREFIX', document.getElementById("PREFIX-sync-" + index).value);
    formData.append('COD_SPEC', document.getElementById("COD_SPEC-sync-" + index).value);

    xhr.send(formData);
    
  }

  function getFacultati(mJson) {
    var facultati = [];

    mJson.forEach((materie) => {
      if(!facultati.includes(materie.facultate))
        facultati.push(materie.facultate);
    });

    setFacultati(facultati);
  }

  function handleImportFileChange(index) {
    if(document.getElementById("import-file-button-" + index).value == "") {
      return;
    }
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/import", true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/json") {
        const blob = new Blob([xhr.response], {type : 'application/json'});
        var response = JSON.parse(await blob.text());
        

        if(response.success == true) {
          let bytes = new Uint8Array(response.data.excel.data.length);  //nu merge direct cu response.data.excel.data in constructorul de la Blob
          for (var i = 0; i < response.data.excel.data.length; i++) {
            bytes[i] = response.data.excel.data[i];
          }

          var excel = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          //Create a DOMString representing the blob and point the link element towards it
          let url = window.URL.createObjectURL(excel);
          a.href = url;
          a.download = document.getElementById("import-file-button-" + index).files[0].name;
          //programatically click the link to trigger the download
          a.click();
          //release the reference to the file by revoking the Object URL
          window.URL.revokeObjectURL(url);
        
          setInfoImportExport(response.message);
          if(response.data.studentiProblema.length != 0) {
            setStudentiProblema(response.data.studentiProblema);
          }
          else {
            setStudentiProblema([]);
          }
          setOpenInfoImportExport(true);
        }
        else {
          setErrorInfoDescription(response.message);
        }
        
      }
      expireCookie("downloaded");
      el.innerHTML = "";
      document.getElementById("import-file-button-" + index).value = "";
      setLoading(!isLoading);
      
      
    }

    const files = document.getElementById("import-file-button-" + index).files;
    const formData = new FormData();
    formData.append('excel', files[0]);
    formData.append('DEN_DISC', document.getElementById("DEN_DISC-import-" + index).value);
    formData.append('PREFIX', document.getElementById("PREFIX-import-" + index).value);
    formData.append('COD_SPEC', document.getElementById("COD_SPEC-import-" + index).value);
    formData.append('FACULTATE', document.getElementById("FACULTATE-import-" + index).value);
    formData.append('PROFIL', document.getElementById("PROFIL-import-" + index).value);
    formData.append('SPECIALIZARE', document.getElementById("SPECIALIZARE-import-" + index).value);
    formData.append('EVALUARE', document.getElementById("EVALUARE-import-" + index).value);
    formData.append('NUME_CADRU', document.getElementById("NUME_CADRU-import-" + index).value);

    xhr.send(formData);
  }

  async function handleUploadFileChange(index) {
    if(document.getElementById("upload-file-button-" + index).value == "") {
      return;
    }

    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/upload", true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/json") {
        const blob = new Blob([xhr.response], {type : 'application/json'});
        var response = JSON.parse(await blob.text());

        if(response.success == false) {
          setErrorInfoDescription(response.message);
        }
        else {
          window.location.href='/upload/preview';
        }
      }
      else {
        
      }
      el.innerHTML = "";
      document.getElementById("upload-file-button-" + index).value = "";
      setLoading(!isLoading);
    }

    const files = document.getElementById("upload-file-button-" + index).files;
    const formData = new FormData();
    formData.append('excel', files[0]);
    formData.append('DEN_DISC', document.getElementById("DEN_DISC-upload-" + index).value);
    formData.append('PREFIX', document.getElementById("PREFIX-upload-" + index).value);
    formData.append('COD_SPEC', document.getElementById("COD_SPEC-upload-" + index).value);
    formData.append('EVALUARE', document.getElementById("EVALUARE-upload-" + index).value);
    formData.append('FACULTATE', document.getElementById("FACULTATE-upload-" + index).value);
    formData.append('PROFIL', document.getElementById("PROFIL-upload-" + index).value);
    formData.append('SPECIALIZARE', document.getElementById("SPECIALIZARE-upload-" + index).value);

    xhr.send(formData);
  }


  function handleMaterieDownloadClick(e, DEN_DISC, PREFIX, COD_SPEC, FACULTATE, PROFIL, SPECIALIZARE, EVALUARE, NUME_CADRU) {
    e.preventDefault();
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var search1 = '\\.';
    var search2 = "\\*";  

    var replacer1 = new RegExp(search1, 'g');
    var replacer2 = new RegExp(search2, 'g');

    var semestru = COD_SPEC.split('.');
    semestru = semestru[semestru.length - 1];
    semestru = parseInt(semestru);

    var AN = Math.ceil(semestru / 2);

    const fileName = AN + "_" + DEN_DISC + "_" + PREFIX.replace(replacer1, '') + "_" + COD_SPEC.replace(replacer1, '').replace(replacer2, '') + ".xlsx";


    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/materii/download", true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/json") {
        const blob = new Blob([xhr.response], {type : 'application/json'});
        var response = JSON.parse(await blob.text());
        setErrorInfoDescription(response.message);
      }
      else if(xhr.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        var blob = new Blob([xhr.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);
      }
      expireCookie("downloaded");
      el.innerHTML = "";
      setLoading(!isLoading);
      
      
    }

    const formData = new FormData();
    // '/materii/download?DEN_DISC=' + materie.denumire + "&PREFIX=" + materie.prefix + "&COD_SPEC=" + materie.cod + "&NUME_CADRU=" + materie.nume
    formData.append('DEN_DISC', DEN_DISC);
    formData.append('PREFIX', PREFIX);
    formData.append('COD_SPEC', COD_SPEC);
    formData.append('FACULTATE', FACULTATE);
    formData.append('PROFIL', PROFIL);
    formData.append('SPECIALIZARE', SPECIALIZARE);
    formData.append('EVALUARE', EVALUARE);
    formData.append('NUME_CADRU', NUME_CADRU);

    xhr.send(formData);
  }

  function handleClickPrevizualizareNote(DEN_DISC, PREFIX, COD_SPEC) {
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/get/note", true);

    xhr.onload = function() {
      setStudentiJson(JSON.parse(xhr.response));
      setOpenPrevizualizareNote(true);
      el.innerHTML = "";
      setLoading(!isLoading);
    }

    const formData = new FormData();
    formData.append('DEN_DISC', DEN_DISC);
    formData.append('PREFIX', PREFIX);
    formData.append('COD_SPEC', COD_SPEC);

    xhr.send(formData);
  }

  const handleClickInfoMaterie = (denumire, facultate, profil, specializare) => {
    setInfoMaterieDenumire(denumire);
    setInfoMaterieFacultate(facultate);
    setInfoMaterieProfil(profil);
    setInfoMaterieSpecializare(specializare);
    // setInfoMaterieSincronizare(sincronizare)
    setOpenInfoMaterie(true);
  };

  const handleCloseInfoMaterie = () => {
    setOpenInfoMaterie(false);
  };

  const handleClickHelpSync = () => {
    setOpenHelpSync(true);
  };

  const handleCloseHelpSync = () => {
    setOpenHelpSync(false);
  };

  const handleClickHelpImport = () => {
    setOpenHelpImport(true);
  };

  const handleCloseHelpImport = () => {
    setOpenHelpImport(false);
  };

  const handleCloseErrorInfo = () => {
    setOpenErrorInfo(false);
  };

  const handleCloseImportExportInfo = () => {
    setOpenInfoImportExport(false);
  };

  const handleClosePrevizualizareNote = () => {
    setOpenPrevizualizareNote(false);
    setStudentiJson([]);
  };

  const handleClickHelpTutorial = () => {
    window.open("/tutorial.mp4");
  };

  function handleFacultateSelectFilterSelected(e) {
    setFacultateFilter(e.target.value);
  }

  function handleSemestruSelectFilterSelected(e) {
    setSemestruFilter(e.target.value);
  }

  return (
    <div className="MateriiCD">
      {/* <Button
        variant="contained"
        className={classes.logoutButton}
        size="large"
        startIcon={<ExitToAppIcon />}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        Deconectează-te
      </Button> */}
      {/* <IconButton 
        component="span"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon fontSize="large" />
      </IconButton> */}

      <Fab 
        size="large"
        className={classes.logoutButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href='/autentificare/logout';
        }}
      >
        <ExitToAppIcon />
      </Fab>
      <div className={classes.pageDiv}>
        <h2 className={classes.margin}>Listă discipline asociate</h2>
        {
          (!uploadEnabled.cadreSem1 && !uploadEnabled.cadreSem2) &&
          (<div className={classes.infoDiv}>
            Încărcarea notelor este momentan indisponibilă
          </div>)
        }
        
        <div className={classes.tutorialDiv}>
          <b>Tutorial</b>
          <ColorFab size="small" color="secondary" aria-label="help-tutorial" className={classes.margin} onClick={() => handleClickHelpTutorial()}>
            <HelpIcon />
          </ColorFab>
        </div>
        <br></br>

        <FormControl className={classes.formControl}>
          <InputLabel>Facultatea</InputLabel>
          <Select
            id="facultateSelectFilter"
            onChange={(e) => handleFacultateSelectFilterSelected(e)}
            disabled={loadingControlDisabled}
          >
            <MenuItem value=''>Toate</MenuItem>
            {facultati.map((facultate) => (
              <MenuItem value={facultate}>{facultate}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel>Semestrul</InputLabel>
          <Select
            id="semestruSelectFilter"
            onChange={(e) => handleSemestruSelectFilterSelected(e)}
            disabled={loadingControlDisabled}
          >
            <MenuItem value=''>Ambele</MenuItem>
            {[1, 2].map((semestru) => (
              <MenuItem value={semestru}>{semestru}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              {/* <TableCell>An studiu</TableCell> */}
              <TableCell><b>An</b></TableCell>
              <TableCell><b>Sem.</b></TableCell>
              <TableCell><b>Descărcare catalog</b></TableCell>
              <TableCell>
                <div className={classes.margin}>
                  <b>{"Încărcare Catalog"}</b>
                </div>
              </TableCell>
              <TableCell>
                <b>{"Ultima sincronizare"}</b>
              </TableCell>
              <TableCell>
                <div className={classes.margin}>
                  <b>{"Conversie 1"}</b>
                  <Fab size="small" color="primary" aria-label="help-sync" className={classes.margin} onClick={() => handleClickHelpSync()}>
                    <HelpIcon />
                  </Fab>
                </div>
              </TableCell>
              <TableCell>
                <div className={classes.margin}>
                  <b>{"Conversie 2"}</b>
                  <Fab size="small" color="primary" aria-label="help-import" className={classes.margin} onClick={() => handleClickHelpImport()}>
                    <HelpIcon />
                  </Fab>
                </div>
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {materiiFiltrateJson.map((materie, index) => ( //materie.PREFIX.replace(replacer1, '') + "_" + materie.COD_SPEC.replace(replacer1, '').replace(replacer2, '')
              <TableRow>
                <TableCell><center>{materie.AN}</center></TableCell>
                <TableCell><center>{materie.semestru}</center></TableCell>
                <TableCell>
                  <Tooltip title="Previzualizare note">
                    <Fab size="small" color="primary" aria-label="previzualizare-note" className={classes.margin} onClick={() => handleClickPrevizualizareNote(materie.denumire, materie.prefix, materie.cod)}>
                      <VisibilityIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Informații disciplină">
                    <Fab size="small" color="primary" aria-label="help-sync" className={classes.margin} onClick={() => handleClickInfoMaterie(materie.denumire, materie.facultate, materie.profil, materie.specializare)}>
                      <InfoIcon />
                    </Fab>
                  </Tooltip>
                  <img src={xlsxLogo} className={classes.xlsxLogo} />
                  <a href="" onClick={(e) => handleMaterieDownloadClick(e, materie.denumire, materie.prefix, materie.cod, materie.facultate, materie.profil, materie.specializare, materie.tip_evaluare, materie.nume)}>{materie.denumire + ((materie.valabilitate != "2023-2024" && materie.valabilitate != "-") ? ` (${materie.valabilitate})` : "")}</a>
                  
                  <br />
                  <div className={classes.specializareDiv}>{materie.specializare == null ? null : (materie.specializare.length > 70 ? (`(${materie.specializare.substr(0 ,60)}...)`) : `(${materie.specializare})`)}</div>
                </TableCell>
                
                <TableCell>
                <form id={"uploadForm-" + index} action="/upload" method="POST" enctype="multipart/form-data">
                  <input type="text" value={materie.denumire} name="DEN_DISC" id={"DEN_DISC-upload-" + index} hidden />
                  <input type="text" value={materie.prefix} name="PREFIX" id={"PREFIX-upload-" + index} hidden />
                  <input type="text" value={materie.cod} name="COD_SPEC" id={"COD_SPEC-upload-" + index} hidden />
                  <input type="text" value={materie.tip_evaluare} name="EVALUARE" id={"EVALUARE-upload-" + index} hidden />
                  <input type="text" value={materie.facultate} name="FACULTATE" id={"FACULTATE-upload-" + index} hidden />
                  <input type="text" value={materie.profil} name="FACULTATE" id={"PROFIL-upload-" + index} hidden />
                  <input type="text" value={materie.specializare} name="FACULTATE" id={"SPECIALIZARE-upload-" + index} hidden />
                  <Tooltip title="Selectați fișierul xlsx">
                    <span>
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        className={classes.input}
                        name="excel"
                        id={"upload-file-button-" + index}
                        type="file"
                        disabled={(materie.semestru == 1 && !uploadEnabled.cadreSem1) || (materie.semestru == 2 && !uploadEnabled.cadreSem2)}
                        onChange={() => handleUploadFileChange(index)}
                      />
                      <label htmlFor={"upload-file-button-" + index}>
                        <Button
                          variant="contained" 
                          color="primary"
                          className={classes.button}
                          startIcon={<PublishIcon />}
                          component="span"
                          disabled={(materie.semestru == 1 && !uploadEnabled.cadreSem1) || (materie.semestru == 2 && !uploadEnabled.cadreSem2)}
                        >
                          Încarcă
                        </Button>
                      </label>
                    </span>
                  </Tooltip>
                </form>
                </TableCell>
                <TableCell>
                  {(materie.LAST_SYNC == undefined || materie.LAST_SYNC == null) ? "-" : <div>{materie.LAST_SYNC.nume}<br />{materie.LAST_SYNC.data}</div>}
                </TableCell>
                <TableCell>
                <form id={"syncForm-" + index} action="/materii/syncCV" method="POST" enctype="multipart/form-data">
                  <input type="text" value={materie.denumire} name="DEN_DISC" id={"DEN_DISC-sync-" + index} hidden />
                  <input type="text" value={materie.prefix} name="PREFIX" id={"PREFIX-sync-" + index} hidden />
                  <input type="text" value={materie.cod} name="COD_SPEC" id={"COD_SPEC-sync-" + index} hidden />
                  <Tooltip title="Selectați fișierul xlsx">
                    <span>
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        className={classes.input}
                        name="excel"
                        id={"sync-file-button-" + index}
                        type="file"
                        onChange={() => handleSyncFileChange(index)}
                      />
                      <label htmlFor={"sync-file-button-" + index}>
                        <Button
                          variant="contained" 
                          color="primary"
                          className={classes.button}
                          startIcon={<PublishIcon />}
                          component="span"
                        >
                          Conversie
                        </Button>
                      </label>
                    </span>
                  </Tooltip>
                </form>
                </TableCell>
                <TableCell>
                <form id={"importForm-" + index} action="/materii/import" method="POST" enctype="multipart/form-data">
                  <input type="text" value={materie.denumire} name="DEN_DISC" id={"DEN_DISC-import-" + index} hidden />
                  <input type="text" value={materie.prefix} name="PREFIX" id={"PREFIX-import-" + index} hidden />
                  <input type="text" value={materie.cod} name="COD_SPEC" id={"COD_SPEC-import-" + index} hidden />
                  <input type="text" value={materie.facultate} name="FACULTATE" id={"FACULTATE-import-" + index} hidden />
                  <input type="text" value={materie.profil} name="PROFIL" id={"PROFIL-import-" + index} hidden />
                  <input type="text" value={materie.specializare} name="SPECIALIZARE" id={"SPECIALIZARE-import-" + index} hidden />
                  <input type="text" value={materie.tip_evaluare} name="EVALUARE" id={"EVALUARE-import-" + index} hidden />
                  <input type="text" value={materie.nume} name="NUME_CADRU" id={"NUME_CADRU-import-" + index} hidden />
                  <Tooltip title="Selectați fișierul xlsx">
                    <span>
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        className={classes.input}
                        name="excel"
                        id={"import-file-button-" + index}
                        type="file"
                        onChange={() => handleImportFileChange(index)}
                      />
                      <label htmlFor={"import-file-button-" + index}>
                        <Button
                          variant="contained" 
                          color="primary"
                          className={classes.button}
                          startIcon={<PublishIcon />}
                          component="span"
                        >
                          Conversie
                        </Button>
                      </label>
                    </span>
                  </Tooltip>
                </form>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Dialog TransitionComponent={Transition} onClose={handleCloseInfoMaterie} aria-labelledby="simple-dialog-info" open={openInfoMaterie}>
        <DialogTitle id="simple-dialog-info">{"Detalii"}</DialogTitle>
        <center className={classes.dialogDescription}>
          <b>{`Denumire: `}</b>{(infoMaterieDenumire || "")}
          <br />
          <b>{`Facultatea: `}</b>{(infoMaterieFacultate || "")}
          <br />
          <b>{`Domeniul: `}</b>{(infoMaterieProfil || "")}
          <br />
          <b>{`Specializarea: `}</b>{(infoMaterieSpecializare || "")}
          {/* <br />
          <b>{`Ultima sincronizare: `}</b>{(infoMaterieSincronizare || "")} */}
        </center>
      </Dialog>
      <Dialog 
        TransitionComponent={Transition}
        onClose={handleCloseHelpSync}
        aria-labelledby="sync-info-title"
        area-aria-describedby="sync-info-description"
        open={openHelpSync}
      >
        <DialogTitle id="sync-info-title">{"SCOP: Conversie catalog de note într-un format definit de utilizator (CV sau alt format)"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="sync-info-description">
          {`Se încarcă un fișier în format XLSX care conține una dintre coloane denumită "ID number", iar pe linii se regăsește marca asociată studenților.  Aplicația returnează un fișier în format XLSX în care va insera patru coloane "P1", "P2", "P3" și "NP" cu notele existente în catalogul de note pentru fiecare marcă găsită în fișierul inițial.`}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        onClose={handleCloseHelpImport}
        aria-labelledby="import-info-title"
        aria-describedby="import-info-description"
        open={openHelpImport}>
        <DialogTitle id="import-info-title">{"SCOP: Conversie dintr-un format definit de utilizator (CV sau alt format) în formatul catalogului de note."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="import-info-description">
            {`Se încarcă un fișier în format XLSX care conține obligatoriu una dintre coloane denumită "ID number" (pe linii se regăsește marca asociată studenților) și cel puțin una dintre coloanele denumite  "P1", "P2", "P3", "NP" (pe linii se regăsesc notele studenților). Aplicația returnează un fișier în format XLSX cu studenții ordonați după formatul catalogului de note. Acest fișier poate fi utilizat la nevoie pentru copy/paste în catalogul de note.`}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog 
        // classes={{paper: classes.errorInfoDialog}}
        TransitionComponent={Transition}
        onClose={handleCloseErrorInfo}
        aria-labelledby="error-info-title"
        aria-describedby="error-info-description"
        open={openErrorInfo}>
        <DialogTitle id="error-info-title">{"Eroare"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-info-description">
            {errorInfoDescription}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog 
        TransitionComponent={Transition}
        fullWidth="true"
        maxWidth="md"
        onClose={handleCloseImportExportInfo}
        aria-labelledby="simple-dialog-import-export-info" 
        open={openInfoImportExport}
      >
        <DialogTitle id="simple-dialog-import-export-info">{`Studenți încărcați cu succes: ${infoImportExport}`}</DialogTitle>
        {studentiProblema.length != 0 &&
          <Table fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell><b>Linie</b></TableCell>
                <TableCell><b>Marca</b></TableCell>
                <TableCell><b>Motiv</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentiProblema.map(student => 
                <TableRow>
                  <TableCell>{student.line}</TableCell>
                  <TableCell>{student.marca}</TableCell>
                  <TableCell>{student.motiv}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
        <DialogActions>
          <Button onClick={handleCloseImportExportInfo} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        TransitionComponent={Transition}
        fullWidth="true"
        maxWidth="lg"
        aria-labelledby="previzualizare-note-title" 
        aria-describedby="previzualizare-note-description"
        open={openPrevizualizareNote}
        onClose={handleClosePrevizualizareNote}
      >
        <DialogTitle id="previzualizare-note-title">{"Note"}</DialogTitle>
        <Table stickyHeader fixedHeader={false} style={{ width: "auto", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell><b>Nr. crt.</b></TableCell>
              <TableCell><b>Marca</b></TableCell>
              <TableCell><b>Grupa</b></TableCell>
              <TableCell><b>Numele și prenumele</b></TableCell>
              <TableCell><b>P1</b></TableCell>
              <TableCell><b>P2</b></TableCell>
              <TableCell><b>P3</b></TableCell>
              <TableCell><b>Nota activitate</b></TableCell>
              <TableCell><b>Nota finală</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentiJson.map((student, index) => 
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{student.MARCA}</TableCell>
                <TableCell>{student.GR_ADM}</TableCell>
                <TableCell>{student.NUME}</TableCell>
                <TableCell><div className={classes.notaText}>{student.PREZ1 != 0 ? student.PREZ1 : <span>&nbsp;</span>}</div></TableCell>
                <TableCell><div className={classes.notaText}>{student.PREZ2 != 0 ? student.PREZ2 : <span>&nbsp;</span>}</div></TableCell>
                <TableCell><div className={classes.notaText}>{student.PREZ3 != 0 ? student.PREZ3 : <span>&nbsp;</span>}</div></TableCell>
                <TableCell><div className={classes.notaText}>{student.NP != 0 ? student.NP : <span>&nbsp;</span>}</div></TableCell>
                <TableCell><div className={classes.notaText}>{student.NOTA != 0 ? student.NOTA : <span>&nbsp;</span>}</div></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleClosePrevizualizareNote} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  ); 
}

export default MateriiSecretariat;
