import React from 'react';
import logo from './logo.svg';
import xlsxLogo from './xlsx.svg';
import Main from './components/Main';
import './App.css';


const App = () => (
  <div className='app'>
    <Main />
  </div>
);

export default App;
