import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  xlsxLogo: {
    marginRight: theme.spacing(1),
    height: 16,
    width: 16,
  },
  dialogDescription: {
    margin: theme.spacing(1),
    textAlign: "justify",
  },
  logoutButton: {
    position: "absolute",
    right: 16,
    top: 116,
  },
  errorInfoDialog: {
    position: "absolute",
    top: theme.spacing(2),
  },
}));

var attempts = 40;
var downloadTimer;

function expireCookie( cName ) {
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function getCookie( name ) {
  var parts = document.cookie.split(name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}


function Teste() {
  const [isLoading, setLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    const el = document.querySelector(".loader-container");
    if (el) {
      el.innerHTML = "";
      setLoading(!isLoading);
    }
  }, []);

  

  function materieDownloadClick() {
    // console.log("??");
    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    downloadTimer = window.setInterval( function() {
      var token = getCookie( "downloaded" );

      if( (token == "true") || (attempts == 0) ) {
          expireCookie("downloaded");
          attempts = 40;
          window.clearInterval( downloadTimer );
          el.innerHTML = "";
          setLoading(!isLoading);
      }

      attempts--;
    }, 500 );
  }

  function handleFormSubmit(e, index) {
    e.preventDefault();

    const el = document.querySelector(".loader-container");
    el.innerHTML = "<div class=\"loader\"></div>";
    setLoading(!isLoading);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/teste/download-lista-conflicte-" + index, true);
    xhr.responseType = 'blob';

    xhr.onload = async function() {
      if(xhr.response.type == "application/json") {
        const blob = new Blob([xhr.response], {type : 'application/json'});
        var response = JSON.parse(await blob.text());
      }
      else if(xhr.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        var blob = new Blob([xhr.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "fac_dubluri.xlsx";
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url);
      }
      expireCookie("downloaded");
      el.innerHTML = "";
      setLoading(!isLoading);
      
      
    }

    xhr.send();
  }



  return (
    <div className="Teste">
      <center>
        <form action="/teste/download-lista-conflicte" method="POST" onSubmit={e => handleFormSubmit(e, 1)}>
          <input type="submit" value="Lista Conflicte" className={classes.margin} />
        </form>
      </center>  

      <center>
        <form action="/teste/download-lista-conflicte-2" method="POST" onSubmit={e => handleFormSubmit(e, 2)}>
          <input type="submit" value="Lista Conflicte 2" className={classes.margin} />
        </form>
      </center>  

      <center>
        <form action="/teste/download-lista-conflicte-3" method="POST" onSubmit={e => handleFormSubmit(e, 3)}>
          <input type="submit" value="Lista Conflicte pe facultate" className={classes.margin} />
        </form>
      </center> 

      <center>
        <form action="/teste/download-lista-conflicte-4" method="POST" onSubmit={e => handleFormSubmit(e, 4)}>
          <input type="submit" value="Lista Conflicte pe sectie" className={classes.margin} />
        </form>
      </center> 
    </div>
  ); 
}

export default Teste;
